import React, {useState, useEffect} from 'react'; 
import { Button, Modal } from 'react-bootstrap';
import { getCookie } from './utils/cookie.js';
//eslint-disable-next-line
import loggit from './utils/Loggit.js'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './/bootstrap/bootstrap-dark.min.css';
import logoBug from './images/afm-bug-lt-blue.png';
// import logoText from './images/logotext-lavender.png';
import volutiaHero from './images/hero-bw.png';

import LogoutButton from './components/LogoutButton.js';
import LoginModal from './modals/Login.js';
import RegisterModal from './modals/Register.js';

import SquigglesModal from './modals/SquigglesModal.js';

function Expo() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);

    const openLoginModal = () => { 
        setLoginModalOpen(true);
        setRegisterModalOpen(false);
    }
    const openRegisterModal = () => {
        setLoginModalOpen(false);
        setRegisterModalOpen(true);
    }
    useEffect(() => {
        const jwtToken = getCookie('jwtToken');
        try {
            if (!jwtToken || jwtToken === 'undefined') {
                console.log('No token present. Opening login.');
                openLoginModal();
            } else {
                setIsLoggedIn(true);
            }
        } catch(error) {
            console.log('Error checking token. Opening login:', error);
            openLoginModal();
        }
    }, []);

    const [showSquigglesModal, setShowSquigglesModal] = useState(false);

    const openSquigglesModal = () => {
        if (!showSquigglesModal) {
            setShowSquigglesModal(true);
        } else {
            setShowSquigglesModal(false);
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            openSquigglesModal();
        }
    }, [isLoggedIn]);

    return (
        <div className="App">
            <Modal  className='blurred-modal' show={isLoginModalOpen} onHide={() => {}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>VOLUTIA | Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoginModal isOpen={isLoginModalOpen} setIsOpen={setLoginModalOpen} openRegisterModal={openRegisterModal}/>
                    <button onClick={openRegisterModal}>Don't have an account? Register</button>
                </Modal.Body>
            </Modal>
            <Modal  className='blurred-modal' show={isRegisterModalOpen} onHide={() => {}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>VOLUTIA | Register</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RegisterModal isOpen={isRegisterModalOpen} setIsOpen={setRegisterModalOpen} openLoginModal={openLoginModal}/>
                    <button onClick={openLoginModal}>Already have an account? Login</button>
                </Modal.Body>
            </Modal>

            <div>
                <SquigglesModal isOpen={showSquigglesModal} onClose={() => setShowSquigglesModal(false)} />
            </div>

        </div>
    );
}

export default Expo;;
