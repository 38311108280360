
//eslint-disable-next-line
import loggit from '../../utils/Loggit.js'
import { MelVisBase } from './MelVisBase.js'

class MelVisExtruder extends MelVisBase{
    constructor(p, options = {}) {
        // loggit.debug(`  <<<<< A new MelVisExtruder is instantiated >>>>>>`);
        super(p, { ...options });
        this.p = p;

        // customizable parameters
        
    }

    update(melSpectrogram, windForce){
        super.update(melSpectrogram, windForce);
        // loggit.debug(`  <<<<< MelVisExtruder update >>>>>>`);
    }
    
    run(){
        super.run();
        // loggit.debug(`  <<<<< MelVisExtruder run >>>>>>`);
    }

    // render is handled by the parent class
    render(){
        for (let i = 0; i < this.melSpectrogram.length; i++){
            this.p.push();
            let x = 0;
            let y = -this.spacing * i;

            let height = this.melSpectrogram[i] * this.options.strokeWidth ;
            let opacity = this.melSpectrogram[i] * 255;
            // this.p.translate(0, 0, y * 0.5);
            this.p.fill(218, 20, 19, opacity);
            this.p.noStroke();
            // console.log('x:', x, 'y:', y, 'width:', this.width, 'height:', height);
            this.p.rect(x + this.options.xOffset, y + this.options.yOffset, this.options.width, height); //
            this.p.pop();
        }
    }

};


export { MelVisExtruder };