// CUSTOM REACT HOOK: AudioDataCapture
// This custom hook is used to capture audio data from the user's microphone and process it for use in the application. 
// It provides methods to start and stop audio data capture, retrieve the captured audio frequency data, 
//              calculate the current volume in decibels, calculate the rolling average volume, calibrate background levels, 
//              and reset the calibration. The hook uses the Web Audio API to capture audio data and perform calculations on it. 
// Depending on the game mechanics of the parent component, the audio data can be filtered to focus on different aspects of the audio signal

import { useRef, useState, useEffect } from 'react';
//eslint-disable-next-line
import loggit from '../utils/Loggit.js'
import { BinSums, BinStacks, BinMeans, BinDeltas } from '../utils/DataModels.js';

import Meyda from 'meyda';
import { ArrayStatisticsTool } from '../utils/StatisticsHelpers.js';
import { buildMelFilterBank, calculateMelSpectrogram } from '../utils/melFilterBank.js';

const AudioDataCapture = () => {
    const ArrayStats = new ArrayStatisticsTool();
    
    const isAudioInitialized = useRef(false);
    const audioCaptureReady = useRef(false);
    const analyserNode = useRef(null);
    const interval = useRef(null);
    
    const refreshRate = useRef(50); // milliseconds - determines how many times per second the audio data is captured, which is essentially the resolution of the game mechanics control
    const fftSize = 2048;
    const analyserMinDecibels = -120; // These are used by the analyserNode to calculate the frequency data 
    const analyserMaxDecibels = -15;   // we should research if these values work data-wise, these were chosen for best visualization results
    const calibrationDefault = -15; // default calibration value for the microphone (using an estimate approximated for device microphones) This assumes that -20 dBFS corresponds to 60 dB SPL test signal
    const calibrationDBFS = useRef(calibrationDefault); // someday we'll provide a means of dynamically setting this for the user through he UI
    const melSpectrogramHistoryLength = useRef(null); // the number of frames for the mel spectrogram summing calculations... loading this from the tensorflow model name
    const melSpectrogramHistory = useRef([]); // we're adding one per interval(refreshRate)
    
    
    // Noise Reduction Parameters
    const noiseHistoryLength = 1000 / refreshRate.current; // 1.5 seconds is a good amount of time to get a good average of the volume levels - 1000 ms
    const noiseHistory = useRef([]); // we're adding one per interval(refreshRate)
    const noiseReductionFactor = 0.1; // this is the factor by which the noise level is reduced to set the floor for the volume levels
    const noiseReductionThreshold = 0.5; // this is the threshold for the noise reduction factor to be applied
    const noiseReduction = useRef(null);
    
    const volumeHistoryLength = 2000 / refreshRate.current;// 2 seconds is a good amount of time to get a good average of the volume levels - 2000 ms
    const volumeHistory = useRef([]); // we're adding one per interval(refreshRate) 
    
    const [mediaStream, setMediaStream] = useState(null);
    const audioData = useRef( new Uint8Array(fftSize / 2));
    
    // These two require melNumBands and frameWidth to be set before they can be properly initialized
    const melSpectrogramData =  useRef(null);
    const melFilterBank =  useRef(null); // will contain the mel frequency triangular filter bank for the mel spectrogram calculations
    
    // Audio Capture Configurable Settings - declared dynamically in the parent modal... passed into this hook with setConfigParameters(...) ========================
    const showOutliers = useRef(true);
    const reduceNoise = useRef(false);
    const showMelVis = useRef(false);
    const showMfcc = useRef(false);
    const modelStructure = useRef(null);
    const numMelBands = useRef(40);
    const preFilter = useRef('none');
    const frameWidth = useRef(10);
    const [preloadComplete, setPreloadComplete] = useState(false);
    let DataModel = null;

    // EXPOSED FUNCTION to set the configuration parameters for the audio data capture dynamically
    function setConfigParameters ({ preFilterArg,
                                    showOutliersArg,
                                    reduceNoiseArg,
                                    showMelVisArg,
                                    showMfccArg,
                                    modelStructureArg,
                                    numMelBandsArg,
                                    frameWidthArg,
                                    refreshRateArg,
                                    preloadCompleteArg}) {
        loggit.ghostingOff();
        loggit.debug('AudioDataCapture Config Parameters >>>>> prefilter: ',  preFilterArg, ' show-outliers: ', showOutliersArg, 'reduce-noise: ', reduceNoiseArg, ' show-melvis: ', showMelVisArg,
                    ' model-structure: ', modelStructureArg, ' num-mel-bands: ', numMelBandsArg, ' frame-width: ', frameWidthArg, ' refresh-rate: ', refreshRateArg);
        
        preFilter.current = preFilterArg;
        showOutliers.current = showOutliersArg;
        reduceNoise.current = reduceNoiseArg;
        showMelVis.current = showMelVisArg;
        showMfcc.current = showMfccArg;
        modelStructure.current = modelStructureArg;
        numMelBands.current = Number(numMelBandsArg);
        frameWidth.current = Number(frameWidthArg);
        refreshRate.current = refreshRateArg;
        
        if (modelStructure.current) { //  if this is null, then there's no predictions being made and there's no need for these
            // set the DataModel to the appropriate model based on the model structure
            switch (modelStructure.current) {
                case 'binSums':
                    DataModel = new BinSums();
                    break;
                case 'binStacks':
                    DataModel = new BinStacks();
                    break;
                case 'binMeans':
                    DataModel = new BinMeans();
                    break;
                case 'binDeltas':
                    DataModel = new BinDeltas();
                    break;
                default:
                    DataModel = new BinSums();
                    break;
            }
            // set the parameters for the DataModel so it knows how to structure the data
            DataModel.setParams(frameWidth.current, numMelBands.current);
            melSpectrogramHistoryLength.current = frameWidth.current;
        }
        
        setPreloadComplete(preloadCompleteArg);
        
        melSpectrogramData.current = Float32Array.from({length: numMelBands.current});
        melFilterBank.current = Float32Array.from({length: (numMelBands.current * (fftSize / 2))});

        startAudioDataCapture(); // initiates the media stream which triggers the audio data capture now that the settings are in place
    };

    useEffect(() => {
        if (preloadComplete && mediaStream && !isAudioInitialized.current) {
        
            // initiate the AudioContext for the mel spectrogram calculations, must be done on user interaction
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            analyserNode.current = audioContext.createAnalyser();
            const source = audioContext.createMediaStreamSource(mediaStream);
            source.connect(analyserNode.current); // Connect the source to the analyserNode.current
            
            analyserNode.current.fftSize = fftSize;
            analyserNode.current.smoothingTimeConstant = 0.0;
            analyserNode.current.minDecibels = analyserMinDecibels;
            analyserNode.current.maxDecibels = analyserMaxDecibels;

            if (showMelVis.current) {
                fetchMelFilterBank(audioContext.sampleRate);
            }
            
            interval.current = setInterval(() => {
                getAudioData();
            }, refreshRate.current);

            isAudioInitialized.current = true;
            loggit.debug('======================= Audio initialized Starting Data Capture =======================');
 
            return () => {
                clearInterval(interval.current);
                audioContext.close();
            };
        } else if (!mediaStream) {
            isAudioInitialized.current = false;
        }
        //eslint-disable-next-line
    }, [mediaStream, preloadComplete]);


    // Define the methods - getRawData, getMelSpectrogram, etc.
    const getAudioData = async () => {
        // first get the frequency data from the audio data event
        let frequencyData = new Uint8Array(analyserNode.current.frequencyBinCount);
        analyserNode.current.getByteFrequencyData(frequencyData); // returns amplitude values for each frequency bin in the array 0-255
        // loggit.debug('    Frequency Data: ', frequencyData)
        if (frequencyData.length ===0 || frequencyData.length < 1024) {  
            loggit.debug('ERROR: Frequency Data is empty or less than 1024. Shutting down audio capture.');
            stopAudioDataCapture();
            audioCaptureReady.current = false;
        } else  {
            audioCaptureReady.current = true;
        }
        audioData.current = frequencyData; // store the frequency data in the global audioData array
        
        // if the noise reduction is set, process the data and add it to the noise history
        if (reduceNoise.current && !noiseReduction.current) {
            noiseHistory.current.push(frequencyData);
            if (noiseHistory.current.length > noiseHistoryLength) {
                noiseHistory.current.shift();
                // need to average these across all frequency bins...
                let historyLength = noiseHistory.current.length;
                let frequencyBinsCount = noiseHistory.current[0].length
                let noiseHistoryAverage = new Array(frequencyBinsCount).fill(0);
                for (let i = 0; i < historyLength; i++){
                    for (let j = 0; j < frequencyBinsCount; j++){
                        noiseHistoryAverage[j] += noiseHistory.current[i][j];
                    }
                }
                noiseHistoryAverage = noiseHistoryAverage.map(x => x / historyLength);
                // noiseReduction.current  = calculateMelSpectrogram(noiseHistoryAverage, melFilterBank.current, numMelBands.current, fftSize);
                noiseReduction.current = noiseHistoryAverage;
                loggit.debug('    Noise Reduction Array: ', noiseReduction.current);
            }
        }

        // add the avg dB level to the volume History array
        let volumedB = currentVolumeInDecibels();
        volumeHistory.current.push(volumedB); 
        if (volumeHistory.current.length > volumeHistoryLength) { // in keeping this list to 30 items, we can get a rolling average of the last 400 milliseconds
            volumeHistory.current.shift();
        }
        // loggit.debug(`     Volume in dB: ${volumedB}   History: ${volumeHistory.current}`);
        
        let floatFrequencyData = null;
        
        if (showMelVis.current) {
            floatFrequencyData = new Float32Array(analyserNode.current.frequencyBinCount);
            analyserNode.current.getFloatFrequencyData(floatFrequencyData); // returns decibels in magnitude for each frequency bin in the array (similar to Librosa's STFT process)
            
            // loggit.debug('    Float Frequency Data: ', floatFrequencyData);
            if (reduceNoise.current && noiseReduction.current) {
                floatFrequencyData = floatFrequencyData.map((value, index) => {
                    let reducedValue = value - noiseReduction.current[index] * noiseReductionFactor;
                    return Math.min(reducedValue, 0)
                });
            }
            // loggit.debug('    >>>>> NoiseReduced Frequency Data: ', floatFrequencyData);
            
            melSpectrogramData.current = calculateMelSpectrogram(floatFrequencyData, melFilterBank.current, numMelBands.current, fftSize); // returns [] if not ready (no triFilterBank)
            // loggit.debug(`    Getting Mel Spectrogram Data: ${melSpectrogramData.current}`);
            if (melSpectrogramHistory.current){ // if this is not null, add the melSpectrogramData to the history
                melSpectrogramHistory.current.push(melSpectrogramData.current); 
                if (melSpectrogramHistory.current.length > melSpectrogramHistoryLength.current) melSpectrogramHistory.current.shift();
            }
        }

        // if (showMfcc.current) {
        //     // use Meyda to get the MFCC data, set the number of coefficients to 20
        //     let timeDomainData = new Float32Array(fftSize);
        //     analyserNode.current.getFloatTimeDomainData(timeDomainData);

        //     // Calculate MFCC
        //     const mfcc = Meyda.extract('mfcc', timeDomainData, {
        //         numberOfMFCCCoefficients: numMelBands.current,
        //     });

        //     if (melSpectrogramHistory.current){ // if this is not null, add the melSpectrogramData to the history
        //         melSpectrogramHistory.current.push(mfcc); 
        //         if (melSpectrogramHistory.current.length > melSpectrogramHistoryLength.current) melSpectrogramHistory.current.shift();
        //     }
        // }

        frequencyData = null; // clear the frequency data array
    };

    async function fetchMelFilterBank(sampleRate) {
        try {
            melFilterBank.current = await buildMelFilterBank(fftSize, numMelBands.current, sampleRate);
            loggit.debug('AudioDatCapture >>>>> Constructed Triangular Mel Filter Bank: ', melFilterBank.current);
        } catch (error) {
            loggit.debug('Error building Mel Filter Bank: ', error);
            return [];    
        }
    };

    function preFilterData(fData) {
        switch (preFilter.current) {
            case 'midrange':
                let newFData = [
                    ...fData.slice(300, 900), // out of 1024 bins, defocus voice signal frequencies and super high frequencies
                    // ...fData.slice(300)
                ];
                return newFData;
            default:
                return fData;
        }
    }

    function convertDBFStoDBSPL(dBFS) {
        // Assuming calibration dBFS corresponds to 60 dB SPL
        const dBOffset = dBFS - calibrationDBFS.current;
        return 60 + dBOffset; // Convert relative dBFS to absolute dB SPL
    }

    const currentVolumeInDecibels = () => {
        if (audioCaptureReady.current && audioData.current.length > 0) {
            let dataProcessing = audioData.current;

            // Remove the noise floor from the audio data
            if (reduceNoise.current && noiseReduction.current) {
                dataProcessing = dataProcessing.map((value, index) => {
                    return value - noiseReduction.current[index] * noiseReductionFactor;
                });
            }

            // Remove outliers from the audio data if set to do so
            if (showOutliers.current) dataProcessing = ArrayStats.removeOutliers(dataProcessing);

            // Pre Filter the frequency data if set to do so
            if (preFilter.current) dataProcessing = preFilterData(dataProcessing);

            dataProcessing = ArrayStats.mean(dataProcessing);

            let dBFS = 20 * Math.log10(Math.max(dataProcessing, 1) / 255);
            const averageDecibels = convertDBFStoDBSPL(dBFS);
            // loggit.debug(`Audio dBSPL: ${averageDecibels}`); // relates to conversational speech levels used by SLPs (60dB)
            
            return averageDecibels;
        } else {
            return null;
        }
    };

    const startAudioDataCapture = () => {
        if (!mediaStream) {
            navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                loggit.ghost('    AudioDatCapture >>>>> initiating the media stream');
                setMediaStream(stream); // triggers the useEffect to start the audio data capture
            })
            .catch(error => {
                loggit.debug('    AudioDatCapture >>>>> Error getting user media: ', error);
            });
        }
    };

    
    //=========================================================================================================================
    //==== EXPOSED METHODS ================================================================================================


    const stopAudioDataCapture = () => {
        if (interval.current) clearInterval(interval.current);
        // if media stream is running, stop it and set to null
        if (mediaStream) {
            loggit.debug("    AudioDatCapture >>>>> Shutting down media stream.");
            mediaStream.getTracks().forEach(track => track.stop());
            setMediaStream(null);
        }
    };

    
    const getAmbientNoiseLevel = () => {
        // take the average background volume for the recent history of volume levels, and return that as the ambient noise level
        // this is used to set the floor for the volume levels within the game system
        let ambientNoiseLevel = null;      

        if (volumeHistory.current.length >= volumeHistoryLength) { // check if the volume history is sufficient to get a good average (controlled by volumeHistoryLength)
            ambientNoiseLevel = ArrayStats.mean(volumeHistory.current);
        }

        return ambientNoiseLevel; // if it returns null, the game will not initialize
    };

    function getCurrentVolumeInDecibels() {
        return currentVolumeInDecibels();
    }

    function getMelSpectrogramData(normalized = true) {
        // loggit.debug('Getting Mel Spectrogram Data: ', melSpectrogramData.current);

        if (normalized) {
            let normalizedMelSpectrogram = ArrayStats.normalizeArray(melSpectrogramData.current);
            loggit.ghost('    AudioDataCapture >>>>> Getting Normalized Mel Spectrogram Data: ', normalizedMelSpectrogram);
            return normalizedMelSpectrogram;
        }

        return melSpectrogramData.current;
    }

    function getMelStructuredData() {
        let binData = DataModel.processData(melSpectrogramHistory.current);
        return binData;
    }

    function collectAudioData() {
        let volume = currentVolumeInDecibels();
        let melSpectrogram = [...melSpectrogramData.current];
        // console.log('     AudioDataCapture Returning Collected Data <<<<< Volume:', volume, 'Mel Spectrogram:', melSpectrogram);
        return volume, melSpectrogram;
    }
    

    // return the necessary methods to retrieve the audio data in different formats
    return {
        setConfigParameters,
        stopAudioDataCapture,
        getAmbientNoiseLevel,
        getCurrentVolumeInDecibels,
        getMelSpectrogramData,
        getMelStructuredData,
        collectAudioData,
    }
    
}

export default AudioDataCapture;