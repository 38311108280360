// WorldEnvironment class contains the visual elements for the Ember game - it is the world in which the game takes place.

//eslint-disable-next-line
import loggit from '../../../utils/Loggit.js'

class WorldEnvironment {
    constructor(p) {
        this.p = p;
        this.particleImage = p.particleImage;
        this.particles = [];
        this.perlinNoiseOffset = 0.02;
        this.particleSpawnCount = 0;
        this.particleSpawnCountMax = 20; // multiplying by 3 to get 150 max (exponential growth)
        this.ringRadius = 0;
        this.ringRadiusMin = 15;
        this.ringRadiusMax = 450;

        this.windVelocity = p.createVector(0, 0);
    }
    
    // method used to add a particle to the world
    addParticle() {
        // calculate random position around a circle of a given radius
        let angle = this.p.random(0, this.p.TWO_PI);
        let x = this.p.cos(angle) * this.ringRadius;
        let y = this.p.sin(angle) * this.ringRadius; 
        this.particles.push(new Particle(this.p, x, y, this.particleImage));
    }

    // update the world based on user input
    update(randomWind, volume, duration) {  
        // map the volume to the particle spawn count
        console.log('volume:', volume);
        this.particleSpawnCount = this.p.map(volume, 0, 90, 1, this.particleSpawnCountMax) * 2; // multiplying by 2 to get it to behave a little more exponentially

        // map duration value to the ring radius... (6 second top end duration)
        this.ringRadius = this.p.map(duration, 0, 3500, this.ringRadiusMin, this.ringRadiusMax);

        // update the wind force
        let windAcceleration = randomWind.copy();

        // apply the user signal input as wind force to all particles
        for (let i = 0; i < this.particles.length; i++) {
            this.particles[i].applyUserInput(windAcceleration);
        }
        
    }

    // set the world in motion
    run() {
        // render all the particles
        for (let particle of this.particles) {
            particle.render();
        }
        
        // remove dead particles
        this.particles = this.particles.filter(particle => !particle.isDead()); 

        // add new particles
        for (let i = 0; i < this.particleSpawnCount; i++) {
            this.addParticle();
        }
    }
}
//-----------------------------------------------------------



class Particle {
    constructor(p, x, y, particleImage) {
        this.p = p;
        this.particleImage = particleImage;
        this.pos = this.p.createVector(x, y);
        let vx = this.p.randomGaussian() * 0.3;
        let vy = this.p.randomGaussian() * 0.3 - 1.0;
        this.velocity = this.p.createVector(vx, vy);
        this.acceleration = this.p.createVector(0, 0);
        this.lifespan = p.random(100, 250);
        // randomize the tint values for a little color variation
        // this.timekeeperintR = p.random(180, 250); 
        // this.timekeeperintG = p.random(180, 250); 
        this.timekeeperintB = p.random(180, 250); 
    }

    applyUserInput(f) {
        // accepts user input force and applies it to the particle's acceleration
        this.acceleration.add(f);

        // Update the particle's velocity, and position
        this.velocity.add(this.acceleration);
        this.pos.add(this.velocity);

        this.acceleration.mult(0); // reset the acceleration

        // increment lifespan countdown timer
        this.lifespan -= 5.0;
    }
        
    render() {
        this.p.push();
        this.p.blendMode(this.p.ADD);
        this.p.translate(this.pos.x, this.pos.y);
        
        this.p.noStroke(); // This is needed for the texture to use transparency
        this.p.texture(this.particleImage);
        // this.p.tint(253, 33, 73, this.lifespan);
        // this.p.tint(this.timekeeperintR, 50, 200, this.lifespan);
        this.p.tint(63, 201, this.timekeeperintB, this.lifespan);
        this.p.square(0, 0, 32);
        this.p.pop();
    }

    // logic to determine if the particle should be removed from the world
    isDead() {
        if (this.lifespan <= 0.0) {
            return true;
        } else {
            return false;
        }
    }
}

export { WorldEnvironment };