import React, { useRef, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

import p5 from 'p5';

import AudioDataCapture from '../hooks/AudioDataCapture'; // a custom hook

const SquigglesModal = ({ isOpen, onClose }) => {
    
    
    // const {
    //     setConfigParameters,
    //     getCurrentVolumeInDecibels, 
    //     } =  AudioDataCapture();

    const sketchRef = useRef(null);
    const myP5 = useRef(null);
    const groundVelocity = useRef(0);
    const maxGroundVelocity = 160;
    let drawStyle = 1; // 0 = dots, 1 = lines

    let parallelCount = 0;
    let maxParallelCount = 35;
    
    useEffect(() => {
        if (isOpen) {
            // setConfigParameters({
            //     preloadCompleteArg: true
            // });

            // let's create an interval that switched from dots to lines every 5 seconds
            setInterval(() => {
                drawStyle = drawStyle === 0 ? 1 : 0;
            }, 10000);

            let intervalId = setInterval(() => {
                parallelCount += 1;
                if (parallelCount >= maxParallelCount) {
                    parallelCount = maxParallelCount;
                    clearInterval(intervalId);
                }
            }, 100);

            myP5.current = new p5(p => {
                const modalBody = document.getElementById('p5-canvas');
                const windowWidth = modalBody.clientWidth;
                const windowHeight = modalBody.clientHeight;
                let theta = 0;
                let amplitude = 0; // wave amplitude ultimately calculated based on groundVelocity (leftover from NeedlesModal.js) but it's a good randomization of the wave height

                const segments = 1;
                const parallelSpacing = 25;
                const step = 25;
                const lineWeight = 8;

                const speed = 0.020;
                const oscillationSpeed = 0.05;
                p.setup = () => {                    
                    p.frameRate(60);
                    p.createCanvas(windowWidth, windowHeight, p.WEBGL);
                }
                p.draw = () => {
                    p.clear();
                    p.background(0);
                    // p.rotateZ(theta);
                    
                    let zoomOffset =  p.sin(p.noise((p.frameCount)*0.0025) * (p.PI*2)) * 250;
                    p.translate(-windowWidth/2, 0, -800 + zoomOffset);
                    // p.translate(-windowWidth/2, 0, -100);
                    
                    
                    // let volume = getCurrentVolumeInDecibels();
                    // console.log('volume: ', volume);
                    // // let's oscillate the amplitude of the waves from slow to fast.
                    // let preamp = p.map( volume, 35, 60, 15, maxGroundVelocity); 
                    // amplitude += (preamp - amplitude) * 0.06; 

                    amplitude = p.map( p.cos(theta + 0.8 * p.sin(p.frameCount*0.0025)), -1, 1, 15, maxGroundVelocity); // square-ish wave

                    
                    p.noFill();
                    let thisPos = p.createVector(0, 0);
                    let nextPos = p.createVector(0, 0);

                    for (let i = -parallelCount/2; i <= parallelCount/2; i++) {
                        
                        let p1lw = p.sin(p.map(i, -parallelCount/2, parallelCount/2, 0, p.PI)) * lineWeight;
                        
                        p.beginShape();
                        p.push();
                        p.strokeWeight(p1lw);
                        p.stroke(200, 200, 200, 255)
                        for (let x = -500; x < p.width + 500 - step; x+= step) {
                            thisPos = p.createVector(x, oscillator(p, x, segments, speed, oscillationSpeed) * amplitude);
                            nextPos = p.createVector(x+step, oscillator(p, x+step, segments, speed, oscillationSpeed) * amplitude);
                            
                            // first we get the vector angle between the two points as theta    
                            let theta = p.atan2(nextPos.y - thisPos.y, nextPos.x - thisPos.x);
                            // now we rotate that angle by 90 degrees to get the angle of the parallel lines
                            let thetaOffset = theta + p.PI/2;

                            let xOffset = p.cos(thetaOffset) * parallelSpacing * i;
                            let yOffset = p.sin(thetaOffset) * parallelSpacing * i;
                            let p1x= thisPos.x + xOffset;
                            let p1y = thisPos.y + yOffset;
                            if (drawStyle === 0) {
                                p.ellipse(p1x, p1y, p1lw, p1lw);
                            } else {
                                p.vertex(p1x, p1y);
                            }
                            // // mirrored parallel
                            // let p2x= thisPos.x - xOffset;
                            // let p2y = thisPos.y - yOffset;
                            // p.vertex(p2x, p2y);
                        }
                        p.pop();
                        p.endShape();

                    }
                    



                    let angularVel = p.sin(p.noise(p.frameCount*0.01) * (p.PI*2)) * 0.01
                    // console.log('angularVel: ', angularVel)
                    theta += angularVel ;
                } 
                
            }, sketchRef.current);
        } 
        return () => {
            if (myP5.current) {
                myP5.current.remove();
                myP5.current = null;
            }
        }

    }, [isOpen]);

    
    const oscillator = (p, x, segments, speed, oscillationSpeed) => {
        // let's map the x position to the range of 0 to 2PI * 4 (which should show 4 waves)
        let xMapped = p.map(x, 0, p.windowWidth, 0, 2 * p.PI * segments);
        xMapped += p.frameCount*speed + p.noise(p.frameCount * 0.01) * 0.5; // this will make the waves move to the right
        


        let a = p.map(groundVelocity.current, 0, maxGroundVelocity, 0, 0.5)
        let b = p.map(groundVelocity.current, 0, maxGroundVelocity, 0, 0.2)
        // const x_fzd = (xMapped + p.frameCount/50) + a * p.sin(x) // HAPPY ACCIDENT using both xMapped and x here causes an oscillation around the main wave pattern which looks like DNA (oscillation within an oscillation)
        const x_fzd = (xMapped + p.frameCount*oscillationSpeed) + a * p.sin(xMapped) // modify the phase
        const y = - (p.cos(x_fzd) + b * p.sin(2 * x_fzd))
        return y
    }


    class trackParticles {
        constructor(p, x, y) {
            this.p = p;
            this.pos = p.createVector(x, y);
            this.radius = p.random(12);
            this.opacity = this.p.random(100)/100 * 150;
        }
        update() {
            this.pos.x += groundVelocity.current;
        }
        display() {
            this.p.push();
            this.p.fill(255, 255, 255, this.opacity);
            this.p.ellipse(this.pos.x, this.pos.y, this.radius, this.radius);
            this.p.pop();
        }
        isDead() {
            return this.pos.x > 2 * this.p.windowWidth;
        }
    }



    const handleClose = () => {
        onClose();
    };

    return (
        <Modal  
        show={isOpen}
        size="xl"
        fullscreen={true} 
        onHide={handleClose}
        className="ember-modal-body">
            <Modal.Header closeButton>
                <Modal.Title>SQUIGGLES</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <div ref={sketchRef} id="p5-canvas" style={{ width: '100%', height: '100%' }}/>
            </Modal.Body>
        </Modal>
    );
}

export default SquigglesModal;