import React, { useRef, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import p5 from 'p5';
//eslint-disable-next-line
import loggit from '../../../utils/Loggit.js';

import volutiaLogo from '../../global/graphics/volutia-logo.png';

import AudioDataCapture from '../../../hooks/AudioDataCapture.js'; // a custom hook
 
import VoiceGameSystem from "../VoiceGameSystem.js";  


function SisyphusModal ({isOpen, onClose}) {
    // dom elements for the sim game world and user feedback
    const simWorldRef = useRef(null);
    const userFeedbackRef = useRef(null);

    const isCalibrated = useRef(false);
    const ambientNoiseLevel = useRef(null);
    const calibrationSeconds = 3; // seconds to wait before setting the ambient noise level
    const fps = 60; // frameRate
    const calibrationTimer = useRef(fps * calibrationSeconds);

    //====== VoiceGameSystem Custom Hook - initializes the game system and runs it based on the user control signals
    const { setSystemNoiseLevel,
            setSystemAudioData,
            setSystemIsReady,
            shutdownGameSystem,
            // pauseGame,
            // resumeGame
        } = VoiceGameSystem({   simWorldRef: simWorldRef, // matter.js dom element for the sim game world
                                userFeedbackRef: userFeedbackRef, // p5.js dom element for user feedback
    });
    
    const preFilter = 'none'; // prefilter the data before evaluating - default is none if not specified, just comment out this parameter
    // we use these to try and ignore sound signals irrelevant to the ACTIVATION SIGNAL the game is based on
    // 'none' is the default and will not apply any filters 
    // 'hipass' filter is used to filter out the lower frequencies which is where the power of the vocal chord signal lives (approximately 80-255dB range for humans)
    // 'midrange' filter is used to filter out the lower and higher frequencies, focusing on the signal activity in the middle  
    // 'lowpass' filter is used to filter out the higher frequencies which is where a lot of irrelevant noise can live
    const showOutliers = true; // false will filter out the utliers - default is true if not specified
    const showMelVis = false; // use mel spectrogram visualizations - default is false if not specified
    const modelStructure = useRef(null); 
    const numMelBands = useRef(process.env.REACT_APP_DEFAULT_NUM_MEL_BANDS);
    const frameWidth = useRef(null);
    const refreshRate = useRef(50);

    // we use these when we want to load a prediction model for the melSpectrogram data, but since we are not using that, just set them to true
    const [settingsLoaded, setSettingsLoaded] = useState(true);
    const [ preloadComplete, setPreloadComplete ] = useState(true);
    
    const {
        setConfigParameters,
        stopAudioDataCapture, 
        getAmbientNoiseLevel,
        collectAudioData, 
        } =  AudioDataCapture();
        

    useEffect(() => {
        if (isOpen && simWorldRef.current && userFeedbackRef.current) {
            resetNoiseCalibration();

            // set the game system configuration parameters, auto starts the audio data capture
            setConfigParameters({
                preFilterArg: preFilter, 
                showOutliersArg: showOutliers, 
                showMelVisArg: showMelVis, 
                modelStructureArg: modelStructure.current, 
                numMelBandsArg: numMelBands.current, 
                frameWidthArg: frameWidth.current, 
                refreshRateArg: refreshRate.current, 
                preloadCompleteArg: preloadComplete
            });

            
            // create an interval to run audio data collection every fps times a second
            const interval = setInterval(runAudioDataCollection, 1000 / fps);

            return () => {
                clearInterval(interval);
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);



    const runAudioDataCollection = () => {
        if (!isCalibrated.current) { // if the ambient noise level is not set...
            // loggit.ghost(`      SisyphusModal CALIBRATING ${isCalibrated.current}>>>>> ambientNoiseLevel: ${ambientNoiseLevel.current} countdown: ${calibrationTimer.current}` );

            // if the timer is still running, decrement the timer
            // if the timer is less than 0 and ambientNoiseLevel is null, log an issue and close the modal
            // if the timer is less than 0 and ambientNoiseLevel is a value > 0, set the ambient noise level and set the system to ready
            if (calibrationTimer.current > 0) { // wait a miminum of x number of seconds
                ambientNoiseLevel.current = getAmbientNoiseLevel(); // returns null if not ready
                calibrationTimer.current--;
            } else {
                if (ambientNoiseLevel.current != null) {
                    loggit.ghost('      SUCCESS <<<<<< Ambient Noise Level Set:', ambientNoiseLevel.current);
                    setSystemNoiseLevel(ambientNoiseLevel.current);
                    isCalibrated.current = true;
                    setSystemIsReady(true);
                } else {
                    loggit.warning(`WARNING >>>>> Calibration Timer has exceeded ${calibrationSeconds} seconds. Unable to set ambient noise level.`);
                    closeModal();
                }
            }
        } else { // isCalibrated is true and ambientNoiseLevel is set... run the game by sending data to the game system   
            let { volume, melSpectrogram } = collectAudioData(); // for this game we want to track both the volume and the melSpectrogram data so using collectAudioData       
            
            // if volume is null, log an issue and skip the update loop
            if (volume === null) {
                loggit.warning('WARNING     SisyphusModal >>>>> Volume is null. Unable to start game system.');
                closeModal();
            }

            // update the game based on the user volume (breath as amplitude, melFrequency data, etc.)
            setSystemAudioData(volume, melSpectrogram);
            loggit.ghost('      SisyphusModal<<<<<< User Volume:', volume, 'Mel Spectrogram:', melSpectrogram.length);

        }
    }

    
    const resetGame = () => {
        // gameSystem.reset(); // calls the reset method on the game environment
        resetNoiseCalibration();
    }    

    const resetNoiseCalibration = () => {
        calibrationTimer.current =  fps * calibrationSeconds; // set the calibration timer to x seconds
        setSystemNoiseLevel(null);
        isCalibrated.current = false;
    }

    const closeModal = () => {
        stopAudioDataCapture();
        resetNoiseCalibration();
        shutdownGameSystem();
        isOpen = false;
        onClose();
    }


    if (!isOpen) { return null; }
    return (
        <Modal 
            show={isOpen}
            size="xl"
            fullscreen={true} 
            onHide={closeModal}
            className="ember-modal-body volutia-bg-light">

            <Modal.Header closeButton>
                <Modal.Title>SISYPHUS 🛞</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex align-items-center justify-content-center">
                <div ref={userFeedbackRef} id="p5-canvas" style={{ position: "absolute", top: "0", left: "0" }} />
                <div ref={simWorldRef} id="matter-canvas" style={{ width: '100%', height: '100%' }}/>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <Button variant="secondary" onClick={resetNoiseCalibration}>CALIBRATE</Button>
                <Button variant="secondary" onClick={resetGame}>RESET</Button>
            </Modal.Footer>
        </Modal>
    );


}

export default SisyphusModal;

