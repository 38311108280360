// BASE SYSTEM CLASS - controls the game & visualization elements and their interactions as well as the user feedback system
// this world is subject to the user control signals generated by the AudioDataCapture component in the parent EmberModal
// Ember Game Mechanics = Breath - volume, duration and rhythm (control)

// this Base System class sets up the base system so that its children can initialize all the necessary element classes 
// then runs them based on the user control signals by extending the update and run methods
// the default visualization element is the MelVisRing and can be hidden by setting the noob parameter to false when instantiating the class

import loggit from '../utils/Loggit.js'

import { MelVisRing } from "./datavis/MelVisRing.js";   

//================================================================================================
//================================================================================================

class VolutiaSystem { 
    constructor(p, noob) {
        this.p = p;
        this.noob = noob || false;

        loggit.ghost(`  <<<<< A VolutiaSystem Class is instantiated >>>>>>`);

        // DEFAULT vis element - hidden when set noob to false
        if (this.noob) {
            this.xOffset1 = this.p.xOffset;
            this.yOffset1 = this.p.yOffset;
            this.melVisRing = new MelVisRing(p, this.xOffset1, this.yOffset1, 800, 7, 0, 360, 50); // args (p, fps, xOffset, yOffset, diameter, strokeWidth, arcAngleStart, arcAngleEnd, detail) {
        }

        this.perlinNoiseOffset = 0.02;
        this.randomWind = this.p.createVector(0, 0);

        this.defaultNoiseLevel = 30; // calculated dBFS level
        this.ambientNoiseLevel = this.defaultNoiseLevel;

        // scale from 0-100... scale is converted from user's decibel level within the calibrated range
        this.minVolume = 75; // change this value dynamically based on user's ability
        this.maxVolume = 95;

        this.volume = 0;

    }

    calibrate(noiseLevel) {
        // calibrate the ambient noise level
        this.ambientNoiseLevel = noiseLevel;
        loggit.info('Calibrated Ambient Noise Level:', this.ambientNoiseLevel);
    }

    update(volume, melSpectrogram) {
        this.volume = volume; // currently not doing anything with this
        
        this.melSpectrogram = [...melSpectrogram];
        // loggit.debug(`          >>>>> VolutiaSystem melSpectrogram: ${this.melSpectrogram}`);

         // update the random wind force vector <<<<<<<<<< comment this out for no wind acceleration
        this.stirWind();
        let windForce = this.randomWind.copy();

        // UPDATE the game elements
        if (this.noob) this.melVisRing.update(this.melSpectrogram, windForce);// DEFAULT vis element - hidden when set noob to false

    }

    stirWind() {
        // calculate the noise time offset based on the cycleCount and the perlinNoiseOffset
        let toff = this.p.frameCount * this.perlinNoiseOffset;
        // wind force - randomized wind direction and strength
        let wx = this.p.cos(this.p.noise(toff) * 10) * 0.07;
        let wy = this.p.sin(this.p.noise(toff + 100) * 0.07);
        // if (wy > 0) {
        //     wy *= -1;
        // }
        this.randomWind.x = wx;
        this.randomWind.y = wy;
    }
    
    run() {
        // RUN all of the game elements
        if (this.noob) this.melVisRing.run(); //  DEFAULT vis element - hidden when set noob to false
    }


}

export { VolutiaSystem };