import React, {useState, useEffect} from 'react'; 
import { Button, Modal } from 'react-bootstrap';
import { getCookie } from './utils/cookie';
//eslint-disable-next-line
import loggit from './utils/Loggit.js'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './/bootstrap/bootstrap-dark.min.css';
import logoBug from './images/afm-bug-lt-blue.png';
// import logoText from './images/logotext-lavender.png';
import volutiaHero from './images/hero-bw.png';

import LogoutButton from './components/LogoutButton.js';
import LoginModal from './modals/Login';
import RegisterModal from './modals/Register';

import EmberModal from './modals/games/EmberModal';
import AudioVisModal from './modals/datavis/SerpentineModal.js';
import LabTestModal from './modals/datavis/TriWizardModal.js';
import SisyphusModal from './games/voice/sisyphus/SisyphusModal';
import AudioProcessorModal from './modals/datavis/DataProcessingModal.js';
import GloWormModal from './modals/GloWormModal.js';
import WindSwellModal from './modals/WindSwellModal.js';
import NeedlesModal from './modals/NeedlesModal.js';
import SquigglesModal from './modals/SquigglesModal.js';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);

    const openLoginModal = () => { 
        setLoginModalOpen(true);
        setRegisterModalOpen(false);
    }
    const openRegisterModal = () => {
        setLoginModalOpen(false);
        setRegisterModalOpen(true);
    }
    useEffect(() => {
        const jwtToken = getCookie('jwtToken');
        try {
            if (!jwtToken || jwtToken === 'undefined') {
                console.log('No token present. Opening login.');
                openLoginModal();
            } else {
                setIsLoggedIn(true); // TECHNICAL DEBT: There should be a quick API call to verify the token
            }
        } catch(error) {
            console.log('Error checking token. Opening login:', error);
            openLoginModal();
        }
    }, []);


    const [showEmberModal, setShowEmberModal] = useState(false);
    const [showAudioVisModal, setShowAudioVisModal] = useState(false);
    const [showLabTestModal, setShowLabTestModal] = useState(false);
    const [showSisyphusModal, setShowSisyphusModal] = useState(false);
    const [showAudioProcessorModal, setShowAudioProcessorModal] = useState(false);
    const [showGloWormModal, setShowGloWormModal] = useState(false);
    const [showWindSwellModal, setShowWindSwellModal] = useState(false);
    const [showSquigglesModal, setShowSquigglesModal] = useState(false);
    const [showNeedlesModal, setShowNeedlesModal] = useState(false);

    const openEmberModal = () => {
        if (!showEmberModal) {
            setShowEmberModal(true);
        } else {
            setShowEmberModal(false);
        }
    };

    const openAudioVisModal = () => {
        if (!showAudioVisModal) {
            setShowAudioVisModal(true);
        } else {
            setShowAudioVisModal(false);
        }
    }

    const openLabTestModal = () => {
        if (!showLabTestModal) {
            setShowLabTestModal(true);
        } else {
            setShowLabTestModal(false);
        }
    }

    const openSisyphusModal = () => {
        if (!showSisyphusModal) {
            setShowSisyphusModal(true);
        } else {
            setShowSisyphusModal(false);
        }
    }

    const openAudioProcessorModal = () => {
        if (!showAudioProcessorModal) {
            setShowAudioProcessorModal(true);
        } else {
            setShowAudioProcessorModal(false);
        }
    }

    const openGloWormModal = () => {
        if (!showGloWormModal) {
            setShowGloWormModal(true);
        } else {
            setShowGloWormModal(false);
        }
    }

    const openWindSwellModal = () => {
        if (!showWindSwellModal) {
            setShowWindSwellModal(true);
        } else {
            setShowWindSwellModal(false);
        }
    }
    
    const openSquigglesModal = () => {
        if (!showSquigglesModal) {
            setShowSquigglesModal(true);
        } else {
            setShowSquigglesModal(false);
        }
    } 

    const openNeedlesModal = () => {
        if (!showNeedlesModal) {
            setShowNeedlesModal(true);
        } else {
            setShowNeedlesModal(false);
        }
    }

    return (
        <div className="App">
            <Modal  className='blurred-modal' show={isLoginModalOpen} onHide={() => {}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>VOLUTIA | Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoginModal isOpen={isLoginModalOpen} setIsOpen={setLoginModalOpen} openRegisterModal={openRegisterModal}/>
                    <button onClick={openRegisterModal}>Don't have an account? Register</button>
                </Modal.Body>
            </Modal>
            <Modal  className='blurred-modal' show={isRegisterModalOpen} onHide={() => {}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>VOLUTIA | Register</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RegisterModal isOpen={isRegisterModalOpen} setIsOpen={setRegisterModalOpen} openLoginModal={openLoginModal}/>
                    <button onClick={openLoginModal}>Already have an account? Login</button>
                </Modal.Body>
            </Modal>

                <div className="App-header" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <img src={volutiaHero} className="App-hero" alt="Volutia - A Vocal Experience" />
                    {/* <img src={logoText} className="p-3" alt="Volutia - A Vocal Experience" /> */}

                        {isLoggedIn && (
                            <>
                        <div style={{ 
                            display: 'flex', 
                            flexDirection: 'row',
                            marginTop: "40px",
                            marginBottom: "20px",
                            }}>
                            <Button variant="dark"  style={{ marginRight: '10px' }} onClick={openEmberModal}>EMBER 🔥</Button>
                            <Button variant="dark"  style={{ marginRight: '10px' }} onClick={openAudioVisModal}>SERPENTINE 🧬</Button>
                            <Button variant="dark" style={{ marginRight: '10px' }} onClick={openLabTestModal}>TRI WIZARD 🔮</Button>
                            <Button variant="dark" style={{ marginRight: '10px' }} onClick={openSisyphusModal}>SISYPHUS 🛞</Button>
                            <Button variant="dark" style={{ marginRight: '10px' }} onClick={openAudioProcessorModal}>DATA PROCESSING 🧮</Button>
                            <Button variant="dark" style={{ marginRight: '10px' }} onClick={openWindSwellModal}>WINDSWELL 🌊</Button>
                            <Button variant="dark" style={{ marginRight: '10px' }}  onClick={openGloWormModal}>GLOWORM 🪼</Button>
                            <Button variant="dark" style={{ marginRight: '10px' }} onClick={openSquigglesModal}>SQUIGGLES 🌀</Button>
                            <Button variant="dark" onClick={openNeedlesModal}>NEEDLES 🥢</Button>
                        </div>
                        </>)}

                    <img src={logoBug} className="App-logo" alt="logo bug" />
                <div className='mt-2 mb-3'>
                    <LogoutButton />
                </div>
                </div>

                <div>
                    <EmberModal isOpen={showEmberModal} onClose={() => setShowEmberModal(false)} />
                    <AudioVisModal isOpen={showAudioVisModal} onClose={() => setShowAudioVisModal(false)} />
                    <LabTestModal isOpen={showLabTestModal} onClose={() => setShowLabTestModal(false)} />
                    <SisyphusModal isOpen={showSisyphusModal} onClose={() => setShowSisyphusModal(false)} />
                    <AudioProcessorModal isOpen={showAudioProcessorModal} onClose={() => setShowAudioProcessorModal(false)} />
                    <GloWormModal isOpen={showGloWormModal} onClose={() => setShowGloWormModal(false)} />
                    <WindSwellModal isOpen={showWindSwellModal} onClose={() => setShowWindSwellModal(false)} />
                    <SquigglesModal isOpen={showSquigglesModal} onClose={() => setShowSquigglesModal(false)} />
                    <NeedlesModal isOpen={showNeedlesModal} onClose={() => setShowNeedlesModal(false)} />
                </div>
            </div>
    );
}

export default App;;
