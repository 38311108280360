//eslint-disable-next-line
import loggit from '../../utils/Loggit.js'
import { VectorStatisticsTool } from '../../utils/StatisticsHelpers.js';

class MelVisMeterLateral {
    constructor(p, melBands) {
        loggit.debug(`A new MelVisMeterLateral is initiated>>>>>> numMelBands: ${melBands}`);

        this.p = p;
        this.melSpectrogram = []
        
        this.defaultNoiseLevel = 30; // calculated dBFS level
        this.ambientNoiseLevel = this.defaultNoiseLevel;
        this.windForce = this.p.createVector(0, 0);

        this.vectorStats = new VectorStatisticsTool();

        // set visual parameters
        this.visWidth = 500;
        this.visHeight = 100;
        this.blockWidth = this.visWidth / melBands;
        this.blockHeight = this.visHeight / 20;


        this.ticks = [];

    }

    calibrate(noiseLevel) {
        // calibrate the ambient noise level
        this.ambientNoiseLevel = noiseLevel;
        console.log('Calibrated Ambient Noise Level:', this.ambientNoiseLevel);
    }

    addTicks(){
        for (let i = 0; i < this.melSpectrogram.length; i++){
            let width = this.blockWidth * this.melSpectrogram[i];
            let height = this.blockHeight * this.melSpectrogram[i];
            let x = i * this.blockWidth - width / 2;
            let y = -this.visHeight * this.melSpectrogram[i];
            let color = this.p.color(255);
            let melVal = this.melSpectrogram[i];
            this.ticks.push(new MelVisTick(this.p, x, y, width, height, color, melVal, this.visWidth, this.visHeight));
        }
    }

    update(melSpectrogram, windForce){
        this.windForce = windForce;
        this.melSpectrogram = melSpectrogram;
        for (let tick of this.ticks){
            tick.applyUserInput();
        }
    }
    
    run(){
        // render all ticks
        for (let tick of this.ticks){
            tick.render();
        }
        // remove dead ticks
        for (let tick of this.ticks){
            if (tick.isDead()){
                this.ticks.splice(tick, 1);
            }
        }
        // add new ticks
        this.addTicks();

        // render the power line
        this.render();
    }

    render(){
        if (this.melSpectrogram.length === 0) {
            loggit.debug(`   \\ MelSpectrogram is empty. make sure it is enabled in the parent modal configuration settings.`);
            return;
        } 
        
        let melVisDataVector = [];
        for (let i = 0; i < this.melSpectrogram.length; i++){
            let x = i * this.blockWidth;
            let y = -(this.melSpectrogram[i] * this.visHeight);
            // loggit.debug(`   \\ melVisDataVector is updating with x: ${x} and y: ${y}`);
            melVisDataVector.push(this.p.createVector(x, y));
        }
        let {slope, intercept} = this.vectorStats.linearRegression(melVisDataVector);
        // loggit.debug(`   \\ MelVis Meter powerline is rendering with slope: ${slope} and intercept: ${intercept}`);

        let powerLine = [];
        for (let i = 0; i < melVisDataVector.length; i++) {
            let x = melVisDataVector[i].x;
            let y = slope * x + intercept;
            powerLine.push(this.p.createVector(x, y));
        }
        this.p.push();
        this.p.translate(this.p.xOffset - this.visWidth / 2, this.p.yOffset*2 - this.visHeight - 100 );
        this.p.stroke(218, 20, 109, 255);
        this.p.strokeWeight(5);
        this.p.line(powerLine[0].x, powerLine[0].y, powerLine[powerLine.length - 1].x, powerLine[powerLine.length - 1].y);     
        this.p.pop();
    }

};

class MelVisTick {
    constructor(p, x, y, width, height, color, melVal, visWidth, visHeight){
        this.p = p;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.color = color;
        this.melVal = melVal;
        this.visWidth = visWidth;
        this.visHeight = visHeight;

        this.lifespan = 255 * this.melVal; 
        this.decayRate = 20 ; 
    }
    
    applyUserInput(f){
        // update the tick apply wind here??
        this.lifespan -= this.decayRate;
    }

    render(){
        // render the tick
        this.p.push();
        this.p.translate(this.p.xOffset - this.visWidth / 2, this.p.yOffset*2 - this.visHeight - 100 );
        this.p.fill(this.color, this.lifespan);
        this.p.rect(this.x, this.y, this.width, this.height);
        this.p.pop();
    }

    isDead(){
        if (this.lifespan <= 0){
            return true;
        } else {
            return false;
        }
    }
};




export { MelVisMeterLateral };