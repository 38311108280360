
import loggit from './Loggit.js';



//_______________________________________________________
//@@@@@@@@@@ HANDLE API REQUEST ERRORS @@@@@@@@@@@@@@@@@@
export async function handleApiError(response) {
    if (!response.ok) {
        let errorData;
        try {
            errorData = await response.json(); // try to get more error details
        } catch {
            errorData = { error: 'Server error' }; // in case the response is not a json
        }
        throw new Error(errorData.error);
    }
    return response;
}
//------------- END handleApiError -----------------------


export function getModelParams(tensorflowModelName) {
    let mname = null;
    let mstruct = null;
    let outshape = null;
    let nmels = null;
    let fw = null;
    let rr = null;
    let success = false;
    loggit.debug('helpers.js  Tensorflow >>>>> getModelParams from: ', tensorflowModelName);
    try {
        // Get the model structure from the folder path before the '/'
        mstruct = tensorflowModelName.split('/')[0];

        // Extract the part after the last '/' for further parsing
        mname = tensorflowModelName.split('/').pop();

        // remove the '-EPOCHS' suffix if it exists
        const epochsMatch = mname.match(/(\d+)-EPOCHS/);
        if (epochsMatch) mname = mname.split('-EPOCHS')[0];

        // Extract the output shape as the last number in the model name
        const outshapeMatch = mname.match(/(\d+)(?!.*\d)/);
        outshape = outshapeMatch ? parseInt(outshapeMatch[0], 10) : null;

        // Now let's remove the model info after the '_MODEL'
        mname = mname.split('_MODEL')[0];

        // Extract parameters using regex
        nmels = (mname.match(/m(\d+)f/) || [])[1] ? parseInt((mname.match(/m(\d+)f/) || [])[1], 10) : null;
        fw = (mname.match(/f(\d+)s/) || [])[1] ? parseInt((mname.match(/f(\d+)s/) || [])[1], 10) : null;
        rr = (mname.match(/r(\d+)m/) || [])[1] ? parseInt((mname.match(/r(\d+)m/) || [])[1], 10) : null;


        loggit.ghostingOff();
        loggit.ghost(`helpers.js  Model Parameters >>>>>  model-name: ${mname}  model-structure:${mstruct} output-layer-shape:${outshape} num-mels:${nmels} frame-width:${fw} refresh-rate:${rr}`);

        success = true;
    } catch (error) {
        loggit.error('ERROR          Tensorflow >>>>> Error getting model parameters: ', error);
    }

    return { mname, mstruct, outshape, nmels, fw, rr, success };
}
