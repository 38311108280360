/**
 * This model is the container that holds the components responsible for audio capture and conversion to data objects.
 * ./components/AudioDataComponent.js is the component that captures the audio data and converts it to data objects.
 * ./components/breath/BreathUCS.js is the component that converts the data objects to user control signals.
 * It also includes the world classes for the game (./components/breath/ember/EmberWorldClasses.js),which contains all the relevant object classes.
 * Additionally, it includes the user control system component, which all work together to enable the user to play the Ember game.
 */
import React, { useRef, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import p5 from 'p5';
//eslint-disable-next-line
import loggit from '../../utils/Loggit.js';

// import { BreathUCS } from '../../components/breath/BreathUCS';

import AudioDataCapture from '../../hooks/AudioDataCapture'; // a custom hook

import { EmberGameSystem } from '../../components/breath/ember/EmberGameSystem'; // a custom class

import img from '../../images/ember-particle.png';
import volutiaLogo from '../../images/volutia-logo.png';

function EmberModal ({isOpen, onClose}) {
    const sketchRef = useRef(null);
    const ambientNoiseLevel = useRef(null);
    const calibrationTimer = useRef(0);
    const myP5 = useRef(null);
    const [gameEnv, setGameEnv] = useState(null);
    
    const fps = 30; // frameRate

    
    const preFilter = 'midrange'; // prefilter the data before evaluating - default is none if not specified, just comment out this parameter
    // we use these to try and ignore sound signals irrelevant to the ACTIVATION SIGNAL the game is based on
    // 'none' is the default and will not apply any filters 
    // 'hipass' filter is used to filter out the lower frequencies which is where the power of the vocal chord signal lives (approximately 80-255dB range for humans)
    // 'midrange' filter is used to filter out the lower and higher frequencies, focusing on the signal activity in the middle  
    // 'lowpass' filter is used to filter out the higher frequencies which is where a lot of irrelevant noise can live
    const showOutliers = false; // false will filter out the utliers - default is true if not specified
    const showMelVis = false; // use mel spectrogram visualizations - default is false if not specified
    const modelStructure = useRef(null); 
    const numMelBands = useRef(process.env.REACT_APP_DEFAULT_NUM_MEL_BANDS);
    const frameWidth = useRef(null);
    const refreshRate = useRef(50);

    // we use these when we want to load a prediction model for the melSpectrogram data, but since we are not using that, just set them to true
    const [settingsLoaded, setSettingsLoaded] = useState(true);
    const [ preloadComplete, setPreloadComplete ] = useState(true);
    
    const {
        setConfigParameters,
        stopAudioDataCapture, 
        getAmbientNoiseLevel,
        getCurrentVolumeInDecibels, 
        getMelSpectrogramData,
        } =  AudioDataCapture();
                                
    // additional things to consider: user history, user preferences, general play settings, etc.
    useEffect(() => {
        if (isOpen && sketchRef.current) {     
            // set the config parameters for the audio data capture, automatically starts the audio data capture
            // TECHNICAL DEBT: we should turn this into a json type object that we can pass in as a single parameter...
            setConfigParameters({
                preFilterArg: preFilter, 
                showOutliersArg: showOutliers, 
                showMelVisArg: showMelVis, 
                modelStructureArg: modelStructure.current, 
                numMelBandsArg: numMelBands.current, 
                frameWidthArg: frameWidth.current, 
                refreshRateArg: refreshRate.current, 
                preloadCompleteArg: preloadComplete
            });

            myP5.current = new p5(p => {
                let imgOpacity;
                let game;
                //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
                // define the canvas type here.... the coordinate system will provide x & y offset values for objects to use.
                // this is so that we can use the same objecgs within both P2D and WEBGL sketches
                p.canvasType = 'WEBGL'; // WEBGL or P2D
                //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

                const thisCanvasType = p.canvasType === 'WEBGL' ? p.WEBGL : p.P2D;

                p.preload = () => {
                    p.particleImage = p.loadImage(img);
                    p.volutiaLogo = p.loadImage(volutiaLogo);
                    p.successFont = p.loadFont('/fonts/Righteous/Righteous-Regular.ttf');
                    p.messageFont = p.loadFont('/fonts/Comfortaa/Comfortaa-Bold.ttf');
                };
                
                p.setup = () => {
                    p.frameRate(fps);
                    const windowWidth = sketchRef.current.clientWidth;
                    const windowHeight = sketchRef.current.clientHeight;
                    
                    p.createCanvas(windowWidth, windowHeight, thisCanvasType);
                    p.xOffset =  p.canvasType === 'WEBGL' ? 0 : p.width/2;
                    p.yOffset =  p.canvasType === 'WEBGL' ? 0 : p.height/2;
                    
                    // &&&&&&&&&&&& Insert any global canvas customizations here &&&&&&&&&&&&&&&
                    // p.angleMode(p.DEGREES);
                    // &&&&&&&&&&&&&&&&&&&&&&&&&&&

                    p.background(0);
                    
                    game = new EmberGameSystem(p, fps);
                    setGameEnv(game);
                };

                p.draw = () => {
                    if (!ambientNoiseLevel.current) {
                        // ENV LOADER - AUDIO CHECK for ambient noise level
                        // get the ambient noise level and set it as the volume floor within the game
                        if (calibrationTimer.current < fps * 2) { // check for x number of seconds
                            calibrationTimer.current++;
                        } else {
                            ambientNoiseLevel.current = getAmbientNoiseLevel(); // returns null if not ready
                            loggit.info('Ambient Noise Level:', ambientNoiseLevel.current);
                            if (ambientNoiseLevel.current !== null) {
                                game.calibrate(ambientNoiseLevel.current);
                                calibrationTimer.current = 0;
                            }
                        }

                        p.push(); // Isolate styling
                        p.textFont(p.messageFont);
                        p.textSize(20);
                        p.textAlign(p.CENTER, p.CENTER);
                        p.fill(255); // Set text color
                        p.text("CALIBRATING... please remain silent. Remember... this is a BREATH game.", 0, 0);
                        p.pop(); // Restore original styling
                    } else {
                        // run the game
                        
                        p.clear();
                        p.background(0);

                        let volume = getCurrentVolumeInDecibels();

                        // if volume is null, log an issue and skip the draw loop
                        if (volume === null) {
                            loggit.debug('Volume is null, skipping draw loop');
                            return;
                        }

                        // draw the volutia logo
                        p.push();
                        // p.blendMode(p.ADD);
                        imgOpacity = p.map(volume, 35, 90, 5, 30);
                        p.tint(255, imgOpacity);
                        let newWidth = 800;
                        let newHeight = p.volutiaLogo.height * (newWidth / p.volutiaLogo.width);
                        p.image(p.volutiaLogo, -newWidth / 2 + 20, -newHeight / 2, newWidth, newHeight);
                        p.pop();
                        
                        game.update(volume); // update the game based on the user volume (breath as amplitude, melFrequency data, etc.)
                        game.run();
                    }
                };
            }, sketchRef.current);
        }

        return () => {
            if (myP5.current) {
                myP5.current.remove();
                myP5.current = null;
            }
        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    
    const resetGame = () => {
        gameEnv.reset();
        resetAmbientNoiseLevels();
    }    

    const resetAmbientNoiseLevels = () => {
        ambientNoiseLevel.current = null;
        calibrationTimer.current = 0;
    }

    const closeModal = () => {
        stopAudioDataCapture();
        onClose();
    }


    if (!isOpen) { return null; }
    return (
        <Modal 
            show={isOpen}
            size="xl"
            fullscreen={true} 
            onHide={closeModal}
            className="ember-modal-body">

            <Modal.Header closeButton>
                <Modal.Title>Ember</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex align-items-center justify-content-center">
                <div ref={sketchRef} id="p5-canvas" style={{ width: '100%', height: '100%' }} />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <Button variant="secondary" onClick={resetAmbientNoiseLevels}>CALIBRATE</Button>
                <Button variant="secondary" onClick={resetGame}>RESET</Button>
            </Modal.Footer>
        </Modal>
    );


}

export default EmberModal;



//==============================================================================================================
//============= p5 BLEND MODES ===============================================================================
// In p5.js, blend modes are used to control how colors from one element are combined with the colors of elements beneath it. These blend modes provide powerful ways to create various visual effects and can significantly alter the appearance of graphics by changing how pixels are mixed.
// Here’s a list of the different blend modes supported in p5.js along with a brief description of each:

// 1. BLEND
// Default blending mode, linear interpolation of colours: 
// C=A×α+B.
// 2. ADD
// Sum of A and B.
// 3. DARKEST
// Only the darkest colour succeeds: 
// C=min(A×α,B).
// 4. LIGHTEST
// Only the lightest colour succeeds: 
// C=max(A×α,B).
// 5. DIFFERENCE
// Subtracts the bottom layer from the top layer or the other way around to always get a positive value.
// 6. EXCLUSION
// Like DIFFERENCE, but with lower contrast.
// 7. MULTIPLY
// Multiplication of the colors; the result is always a darker picture.
// 8. SCREEN
// Opposite of multiply, using inverted values. The result is a brighter picture.
// 9. REPLACE
// Only the colors of the source image are shown. The destination image is ignored.
// 10. OVERLAY
// Combines MULTIPLY and SCREEN blend modes. The parts of the top layer where base layer is light become lighter, the parts where the base layer is dark become darker.
// 11. HARD_LIGHT
// Either multiplies or screens the colors, depending on the source color value. Similar to OVERLAY, but top and bottom layers are swapped.
// 12. SOFT_LIGHT
// Soft version of HARD_LIGHT. Uses the darkness or lightness of the bottom layer to influence the top layer's colors, but less dramatically.
// 13. DODGE
// Divides the bottom layer by the inverted top layer.
// 14. BURN
// Divides the inverted bottom layer by the top layer, and then inverts the result.
// 15. SUBTRACT
// Subtracts the top layer from the bottom layer.