// USER FEEDBACK Environment to track game progress, IN-GAME MESSAGE, DURATION INDICATOR
// handles the logic for game progress tracking, in-game messages, and duration indicators

//eslint-disable-next-line
import loggit from '../../../utils/Loggit.js'

class UserFeedbackEnvironment {
    constructor(p, fps, minVolume, maxVolume) {
        this.p = p;  
        this.fps = fps;   
        this.challengeMinDuration = 800; //milliseconds
        this.challengePassDuration = 4000; //milliseconds
        this.totalChallenges = 5;
        this.challengeCount = 0;
        this.isChallengeComplete = false;
        this.isChallengeActive = true;
        this.resetChallenge = false;
        this.minVolume = minVolume;
        this.maxVolume = maxVolume;

        this.messages = new InGameMessages(p, this.fps);
        this.indicators = new DurationIndicators(p);
    }

    update(cycleCount, userDuration, userVolume, userStartTime, scoreKeeper) {
        // updates the game progress tracking parameters - contains the logic for the duration indicator and when to render in-game messages
        // loggit.debug('User Duration:', userDuration);
        let timeElapsed = 0;

        if (userStartTime > 0) {
            timeElapsed = this.p.millis() - userStartTime;
        }

        // update messages and indicators
        let scoreValue = Math.floor(timeElapsed/1000);
        if (scoreValue === 0) scoreValue = '';

        // if certain conditions are met, set an in-game message
        if (this.resetChallenge) {
            this.messages.applyUserInput('');
        } else if (userDuration > this.challengePassDuration) {
            this.messages.applyUserInput('Well Done!!');
        } else {
            this.messages.applyUserInput(scoreValue);
        }        
        this.indicators.applyUserInput(cycleCount, userDuration);

        // if conditions are met, add a new scorekeeper to the scorekeeper array
        if (userDuration >= this.challengeMinDuration) {
            if (userVolume > this.minVolume && userVolume < this.maxVolume && !this.resetChallenge) {
                this.isChallengeActive = true;
            } else if (this.isChallengeActive && userVolume < this.minVolume) { 
                // CHALLENGE ENDED - challenge is active, volume has dropped... so ending this attempt
                this.isChallengeActive = false;
                this.challengeCount++;
                scoreKeeper.addScore(timeElapsed/1000);
                this.resetChallenge = true; // set the reset flag so duration has to go back to zero before the next challenge can start

                if (this.challengeCount === this.totalChallenges) {
                    this.isChallengeComplete = true;
                }
            }
            // loggit.debug('Challenge Active:', this.isChallengeActive);
        } else if (userDuration === 0) {
            this.resetChallenge = false;
        }
    }
    
    run() {
        // render all messages and indicators
        this.messages.render();
        this.indicators.render();
    }
}
//---------------------------------------------------------------------------------------------------------------------



class InGameMessages {
    constructor(p, copy, fps) {
        this.p = p;
        this.fps = fps;
        this.copy = copy;
        this.font = p.successFont;
        this.fontSize = 40;

        this.xpos = 20;
        this.ypos = 14;
        this.lifespan = this.fps * 2;
    }
    
    applyUserInput(copy) {
        this.copy = copy;
    }
    
    render() {
        this.p.push(); // Isolate styling
        this.p.textFont(this.font);
        this.p.textSize(this.fontSize);
        this.p.textAlign(this.p.CENTER, this.p.CENTER);
        this.p.fill(255); // Set text color
        this.p.text(this.copy, this.xpos, this.ypos);
        this.p.pop(); // Restore original styling
    }
}

class DurationIndicators {
    // a series of rings that appear as the user's duration increases (1 per second of duration), increasing in size
    constructor(p) {
        this.p = p;
        this.duration = 0;
        this.initialRadius = 30;
        this.radiusSpacing = 7;
        this.strokeWeight = 0.25;
        this.strokeColor = p.color(255, 255, 255, 100);
    }

    applyUserInput(cycleCount, userDuration) {
        this.duration = Math.floor(userDuration/1000 * 4);
    }
    
    render() {
        this.p.push();
        this.p.noFill();
        this.p.stroke(this.strokeColor);
        for (let i = 0; i < this.duration; i++) {
            this.p.strokeWeight(this.strokeWeight * i);
            this.p.beginShape();
            const numVertices = 120;
            const radius = this.initialRadius + (i * this.radiusSpacing);
            for (let i = 0; i < numVertices; i++) {
              let angle = this.p.TWO_PI / numVertices * i;
              let x = radius * this.p.cos(angle) + 20;
              let y = radius * this.p.sin(angle) + 20;
              this.p.vertex(x, y);
            }
            this.p.endShape(this.p.CLOSE);
        }
        this.p.pop();

    }

}

export { UserFeedbackEnvironment };