import React, { useRef, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

import p5 from 'p5';


const NeedlesModal = ({ isOpen, onClose }) => {
    const sketchRef = useRef(null);
    const myP5 = useRef(null);
    let flowStrength = 1.5; // Adjust this to simulate increasing fluid flowing strength
    const groundVelocity = useRef(0);
    const maxGroundVelocity = 200;
    const groundLevel = 750; // logging this showed 951 as the lowest point
    const drag = 0.99; // drag coefficient to slow down the ground velocity when the feet are not touching the ground

    useEffect(() => {
        if (isOpen) {
            myP5.current = new p5(p => {
                const modalBody = document.getElementById('p5-canvas');
                const windowWidth = modalBody.clientWidth;
                const windowHeight = modalBody.clientHeight;
                let particles = [];
                let theta = 0;
                
                // wave amplitude
                let amplitude = 0; 



                const parallelCount = 20;
                const parallelSpacing = 25;
                const lineWeight = 5;
                const segments = 1;
                
                const step = 90;

                const speed = 0.050;
                const oscillationSpeed = 0.05;
                let horizonLine = parallelSpacing * parallelCount + 200;


                p.setup = () => {                    
                    p.frameRate(60);
                    p.createCanvas(windowWidth, windowHeight, p.WEBGL);
                    
                    for (let i = 0; i < 2500; i++) {
                        particles.push(new trackParticles(p, p.random(windowWidth*4)-windowWidth*2, p.random(400 + horizonLine)));
                    }

                }
                p.draw = () => {
                    p.clear();
                    p.background(0);
                    // p.rotateZ(theta);
                    
                    // let zoomOffset =  p.sin(p.noise((p.frameCount)*0.0025) * (p.PI*2)) * 250;
                    // p.translate(-windowWidth/2, 0, -800 + zoomOffset);
                    p.translate(-windowWidth/2, 0, -1800);
                    
                    // let's oscillate the amplitude of the waves from slow to fast.
                    // amplitude = p.map(p.sin(p.frameCount*0.005), -1, 1, 30, maxGroundVelocity); 
                    amplitude = p.map( p.cos(theta + 0.8 * p.sin(p.frameCount*0.0025)), -1, 1, 18, maxGroundVelocity); // square-ish wave
                    // amplitude = p.map(p.mouseY, 0, p.height, maxGroundVelocity, 30); // use mouseY to control the amplitude

                    
                    p.noFill();
                    let thisPos = p.createVector(0, 0);
                    let nextPos = p.createVector(0, 0);
                    let footPlacementY = 0;
                    let footPlacementX = 0;
                    let fastestVelocity = 0

                    for (let i = 0; i <= parallelCount; i++) {
                        
                        let p1lw = p.map(i, 0, parallelCount, lineWeight, 0);
                        
                        p.beginShape();
                        p.push();
                        p.strokeWeight(p1lw);
                        p.stroke(200, 200, 200, 255)
                        for (let x = 0; x < p.width - step; x+= step) {
                            thisPos = p.createVector(x, oscillator(p, x, segments, speed, oscillationSpeed) * amplitude);
                            nextPos = p.createVector(x+step, oscillator(p, x+step, segments, speed, oscillationSpeed) * amplitude);
                            
                            // first we get the vector angle between the two points as theta    
                            let theta = p.atan2(nextPos.y - thisPos.y, nextPos.x - thisPos.x);
                            // now we rotate that angle by 90 degrees to get the angle of the parallel lines
                            let thetaOffset = theta + p.PI/2;

                            let squishedSpacing = p.cos(p.map(x, 0, p.width - step, 0, p.TWO_PI))*250 + parallelSpacing * i; // squish the overall height in the middle and expand it at the edges

                            let xOffset = p.cos(thetaOffset) * squishedSpacing;
                            let yOffset = p.sin(thetaOffset) * squishedSpacing;
                            let p1x= nextPos.x + xOffset;
                            let p1y = nextPos.y + yOffset;
                            p.vertex(p1x, p1y);
                            
                            // mirrored parallel
                            const mirroredShortening = p.map(x, 0, p.width - step, 1.25, 0.25);
                            let p2x= thisPos.x - xOffset;
                            let p2y = (thisPos.y - yOffset) * mirroredShortening;
                            p.vertex(p2x, p2y);
                            if(p1y > footPlacementY) {
                                footPlacementY = p1y;
                                footPlacementX = p1x;
                            }
                            

                            fastestVelocity = fastestVelocity > (nextPos.y - thisPos.y) ? fastestVelocity : (nextPos.y - thisPos.y);
                        }
                        
                        p.pop();
                        p.endShape();
                        
                    }
                    
                    // if the feet are touching the ground, set the ground velocity to the fastest velocity otherwise slow it down
                    // console.log('     Feet: ', footPlacementY, '    fastestVelocity: ', fastestVelocity)
                    if (footPlacementY > groundLevel) {
                        groundVelocity.current = fastestVelocity;
                        p.push();
                        p.noStroke();
                        p.fill(120);
                        p.ellipse(footPlacementX, footPlacementY, 15, 10);
                        p.pop();
                    } else {
                        groundVelocity.current *= drag;
                    }
                    
                    p.push();
                    p.rotateX(p.PI/2 * 0.75);
                    p.translate(0, 350, 0)
                    let deadParticles = 0;
                    for (let i = 0; i < particles.length; i++) {
                        particles[i].update();
                        particles[i].display();
                        if (particles[i].isDead()) {
                            particles.splice(i, 1);
                            deadParticles++;
                        }
                    }
                    particles = particles.filter(particle => !particle.isDead());
                    if (deadParticles > 0) {
                        for (let i = 0; i < deadParticles; i++) {
                            particles.push(new trackParticles(p, p.random(windowWidth*4)-windowWidth*2, p.random(400 + horizonLine)));
                        }
                    }
                    p.pop();



                    let angularVel = p.sin(p.noise(p.frameCount*0.01) * (p.PI*2)) * 0.01
                    // console.log('angularVel: ', angularVel)
                    theta += angularVel ;
                } 
                
            }, sketchRef.current);
        } 
        return () => {
            if (myP5.current) {
                myP5.current.remove();
                myP5.current = null;
            }
        }

    }, [isOpen]);

    
    const oscillator = (p, x, segments, speed, oscillationSpeed) => {
        // let's map the x position to the range of 0 to 2PI * 4 (which should show 4 waves)
        let xMapped = p.map(x, 0, p.windowWidth, 0, 2 * p.PI * segments);
        xMapped += p.frameCount*speed + p.noise(p.frameCount * 0.01) * 0.5; // this will make the waves move to the right
        


        let a = p.map(groundVelocity.current, 0, maxGroundVelocity, 0, 0.5)
        let b = p.map(groundVelocity.current, 0, maxGroundVelocity, 0, 0.2)
        // const x_fzd = (xMapped + p.frameCount/50) + a * p.sin(x) // HAPPY ACCIDENT using both xMapped and x here causes an oscillation around the main wave pattern which looks like DNA (oscillation within an oscillation)
        const x_fzd = (xMapped + p.frameCount*oscillationSpeed) + a * p.sin(xMapped) // modify the phase
        const y = - (p.cos(x_fzd) + b * p.sin(2 * x_fzd))
        return y
    }


    class trackParticles {
        constructor(p, x, y) {
            this.p = p;
            this.pos = p.createVector(x, y);
            this.radius = p.random(12);
            this.opacity = this.p.random(100)/100 * 150;
        }
        update() {
            this.pos.x += groundVelocity.current;
        }
        display() {
            this.p.push();
            this.p.fill(255, 255, 255, this.opacity);
            this.p.ellipse(this.pos.x, this.pos.y, this.radius, this.radius);
            this.p.pop();
        }
        isDead() {
            return this.pos.x > 2 * this.p.windowWidth;
        }
    }



    const handleClose = () => {
        onClose();
    };

    return (
        <Modal  
        show={isOpen}
        size="xl"
        fullscreen={true} 
        onHide={handleClose}
        className="ember-modal-body">
            <Modal.Header closeButton>
                <Modal.Title>NEEDLES</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <div ref={sketchRef} id="p5-canvas" style={{ width: '100%', height: '100%' }}/>
            </Modal.Body>
        </Modal>
    );
}

export default NeedlesModal;