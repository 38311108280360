import loggit from './Loggit.js';
import { VectorStatisticsTool } from './StatisticsHelpers.js';

// We're creating a series of data models that we will use to train our machine learning models. 
// We will use the Mel Filter Bank to extract features from the audio files and then use the Tensorflow.js library to train the model. 
// We will then use the model to make predictions on new audio files.

// Each class will construct the data in a different way and focus on different aspects of the data - user signals converted to data to control the game mechanics (breath, voice, articulation)

// This first class will be the master class that other classes will extend from
// accepts the melSpectrogram data and returns the normalized data... processing logic will be in the subclasses
class DataModel {
    constructor(){
        this.frameWidth = null; // eg for 3 frames, if 50ms refresh rate => "listening to" 150ms at a time
        this.numMels = null;
    }

    setParams(frameWidth, numMels){
        this.frameWidth = frameWidth;
        this.numMels = numMels;
    }
    
    // let's make sure the data is the correct shape before we process it
    checkData(data){
        if (!data) return false;
        // loggit.ghostingOff();
        loggit.ghost('     DataModel Input Data >>>>> is ', data.length, ' = ', this.frameWidth, ' and ', data[0].length, ' = ', this.numMels, data);
        if (data.length === this.frameWidth && data[0].length === this.numMels){
            return true;
        } else {
            return false;
        }
    }
    // normalize the data using LOCAL FRAME MIN/MAX normalization
    normalizeData(data){
        let min = Math.min(...data);
        let max = Math.max(...data);
        for (let i = 0; i < data.length; i++) {
            data[i] = (data[i] - min) / (max - min);// LOCAL FRAME MIN/MAX normalization
        }
        return data;
    }
}

// binSums 
// METHODOLOGY - this method will sum the melSpectrogram data for each bin across the number of frames (frameWidth)
// - the data will be stored in a 1D array with (numMels) elements
// - we normalize the data before returning it using min-max normalization
// THEORY - the sum of the melSpectrogram data for each bin across the frames will retain bin frequency magnitude information from each frame more accurately than simply averaging the data across the frames. (we can create another datamodel for that) 
// - we can adjust the frameWidth to capture more or less data, which not only determines the resolution of the data and the speed of the model, 
// - but also translates into the responsiveness of the game mechanics... a wider frameWidth causes longer response times in recognizing new patters produced
class BinSums extends DataModel {
    constructor(){
        super();
    }
    processData(data){
        if (this.frameWidth === null || this.numMels === null){
            loggit.debug('WARNING     DataModel >>>>> FrameWidth and NumMels are still null');
            return [];
        }
        if (this.checkData(data)){
            let sums = new Array(this.numMels).fill(0);
            for (let i = 0; i < this.frameWidth; i++){
                for (let j = 0; j < this.numMels; j++){
                    sums[j] += data[i][j];
                }
            }
            sums = this.normalizeData(sums);          
            loggit.ghost('     DataModel Output >>>>>  BinSums Normalized: ', sums);
            return sums;
        } else {
            loggit.error('ERROR     DataModel >>>>> Data is not the correct shape (frameWidth, numMels)')
            return null;
        }
    }
}

// binSumsMirrored
// METHODOLOGY - the same as binSums but will mirror the data vertically to double the number of bins and to add symmetry to the data, ex. assuming 20 mel bins, using features 0-19 then 19-0 (40)
// - the data will be stored in a 1D array with (numMels * 2) elements
// - we normalize the data before returning it using min-max normalization
// THEORY - the mirrored data adds symmetry and could provide additional information to the model, reinforcing the patterns, which may help it to learn more quickly and accurately
class BinSumsMirrored extends DataModel {
    constructor(frameWidth, numMels){
        super(frameWidth, numMels);
    }
    processData(melSpectrogram){
        if (this.checkData(melSpectrogram)){
            let sums = new Array(this.numMels * 2).fill(0);
            for (let i = 0; i < this.frameWidth; i++){
                for (let j = 0; j < this.numMels; j++){
                    let binMagnitude = melSpectrogram[i][j];
                    sums[j] += binMagnitude;
                    sums[(this.numMels * 2) - j] += binMagnitude;
                }
            }
            sums = this.normalizeData(sums);
            loggit.ghost('     DataModel Output >>>>>  BinSumsMirrored Normalized: ', sums);
            return sums;
        } else {
            loggit.error('ERROR     DataModel >>>>> Data is not the correct shape (frameWidth, numMels)')
            return null;
        }
    }
}

// binStacks
// METHODOLOGY - this method will stack the melSpectrogram data for each bin across the number of frames (frameWidth)
// eg frameWidth = 3 & numMels = 4 >>> [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10, 11, 12]] => [1, 4, 7, 10, 2, 5, 8, 11, 3, 6, 9, 12]
// - the data will be stored in a 1D array with (numMels * frameWidth) elements
// - we normalize the data before returning it using min-max normalization
// THEORY - stacking the data will retain the bin frequency magnitude information from each frame and will also capture the changes in the data across the frames in greater detail than binSums
class BinStacks extends DataModel {
    constructor(frameWidth, numMels){
        super(frameWidth, numMels);
    }
    processData(melSpectrogram){
        if (this.checkData(melSpectrogram)){
            let stacks = new Array(this.numMels * this.frameWidth).fill(0);
            for (let i = 0; i < this.frameWidth; i++){
                for (let j = 0; j < this.numMels; j++){
                    stacks[(j * this.frameWidth) + i] = melSpectrogram[i][j];
                }
            }
            stacks = this.normalizeData(stacks);

            loggit.ghost('     DataModel Output >>>>>  BinStacks Normalized: ', stacks);
            return stacks;
        } else {
            loggit.error('ERROR     DataModel >>>>> Data is not the correct shape (frameWidth, numMels)')
            return null;
        }
    }
}

// binStacksMirrored
// METHODOLOGY - the same as binStacks but will mirror the data vertically to double the number of bins and to add symmetry to the data, ex. assuming 20 mel bins, using features 0-19 then 19-0 (40)
// - the data will be stored in a 1D array with (numMels * frameWidth *  2) elements
// - we normalize the data before returning it using min-max normalization
// THEORY - the mirrored data adds symmetry and could provide additional information to the model, reinforcing the patterns, which may help it to learn more quickly and accurately
class BinStacksMirrored extends DataModel {
    constructor(frameWidth, numMels){
        super(frameWidth, numMels);
    }
    processData(melSpectrogram){
        if (this.checkData(melSpectrogram)){
            let stacks = new Array(this.numMels * this.frameWidth * 2).fill(0);
            for (let i = 0; i < this.frameWidth; i++){
                for (let j = 0; j < this.numMels; j++){
                    let binMagnitude = melSpectrogram[i][j];
                    stacks[(j * this.frameWidth) + i] = binMagnitude;
                    stacks[(this.numMels * this.frameWidth * 2) - (j * this.frameWidth) - i] = binMagnitude;
                }
            }
            stacks = this.normalizeData(stacks);
            loggit.ghost('     DataModel Output >>>>>  BinStacksMirrored Normalized: ', stacks);
            return stacks;
        } else {
            loggit.error('ERROR     DataModel >>>>> Data is not the correct shape (frameWidth, numMels)')
            return null;
        }
    }
}

// binMeans
// METHODOLOGY - this method will average the melSpectrogram data for each bin across the number of frames (frameWidth)
// - the data will be stored in a 1D array with (numMels) elements
// - we normalize the data before returning it using min-max normalization
// THEORY - averaging the data across the frames will retain the bin frequency magnitude information from each frame but will not capture the changes in the data across the frames in as much detail as binStacks
class BinMeans extends DataModel {
    constructor(frameWidth, numMels){
        super(frameWidth, numMels);
    }
    processData(melSpectrogram){
        if (this.checkData(melSpectrogram)){
            let means = new Array(this.numMels).fill(0);
            for (let i = 0; i < this.frameWidth; i++){
                for (let j = 0; j < this.numMels; j++){
                    means[j] += melSpectrogram[i][j];
                }
            }
            means = means.map(x => x / this.frameWidth);
            means = this.normalizeData(means);
            loggit.ghost('     DataModel Output >>>>>  BinMeans Normalized: ', means);
            return means;
        } else {
            loggit.error('ERROR     DataModel >>>>> Data is not the correct shape (frameWidth, numMels)')
            return null;
        }
    }
}

// binMeansMirrored
// METHODOLOGY - the same as binMeans but will mirror the data vertically to double the number of bins and to add symmetry to the data, ex. assuming 20 mel bins, using features 0-19 then 19-0 (40)
// - the data will be stored in a 1D array with (numMels * 2) elements
// - we normalize the data before returning it using min-max normalization
// THEORY - the mirrored data adds symmetry and could provide additional information to the model, reinforcing the patterns, which may help it to learn more quickly and accurately
class BinMeansMirrored extends DataModel {
    constructor(frameWidth, numMels){
        super(frameWidth, numMels);
    }
    processData(melSpectrogram){
        if (this.checkData(melSpectrogram)){
            let means = new Array(this.numMels * 2).fill(0);
            for (let i = 0; i < this.frameWidth; i++){
                for (let j = 0; j < this.numMels; j++){
                    let binMagnitude = melSpectrogram[i][j];
                    means[j] += binMagnitude;
                    means[(this.numMels * 2) - j] += binMagnitude;
                }
            }
            means = means.map(x => x / this.frameWidth);
            means = this.normalizeData(means);
            loggit.ghost('     DataModel Output >>>>>  BinMeansMirrored Normalized: ', means);
            return means;
        } else {
            loggit.error('ERROR     DataModel >>>>> Data is not the correct shape (frameWidth, numMels)')
            return null;
        }
    }
}

// binDeltas
// METHODOLOGY - this method will calculate the deltas for the melSpectrogram data for each bin across the number of frames (frameWidth) converting multiple frames to 2 points representing the best fit line of each group
// - the data will be stored in a 1D array with (numMels) elements
// - we normalize the data before returning it using min-max normalization
// THEORY - the deltas will capture the changes in the data across the frames in smoother detail vs binMeans and binSums that just represent an accumulated magnitude
class BinDeltas extends DataModel {
    constructor(frameWidth, numMels) {
        super(frameWidth, numMels);
        this.VectorStats = new VectorStatisticsTool();
    }

    processData(melSpectrogram) {
        if (this.checkData(melSpectrogram)) {        
            let deltas = new Array(this.numMels * 2).fill(0);

            if (this.frameWidth > 2) {
                for (let k = 0; k < this.numMels; k++) {
                    const xIndices = Array.from({ length: this.frameWidth }, (_, i) => i);
                    const yValues = xIndices.map(i => melSpectrogram[i][k]);

                    const pointsArray = xIndices.map((x, i) => ({ x, y: yValues[i] }));
                    const { slope, intercept } = this.VectorStats.linearRegression(pointsArray);

                    deltas[2 * k] = intercept; // Start point (intercept)
                    deltas[2 * k + 1] = slope * (this.frameWidth - 1) + intercept; // End point
                }
            } else {
                for (let k = 0; k < this.numMels; k++) {
                    deltas[2 * k] = melSpectrogram[0][k];
                    deltas[2 * k + 1] = melSpectrogram[this.frameWidth - 1][k];
                }
            }

            loggit.ghost('     DataModel Output >>>>>  BinDeltas Normalized: ', deltas);
            deltas = this.normalizeData(deltas);
            return deltas;
        } else {
            loggit.error('ERROR     DataModel >>>>> Data is not the correct shape (frameWidth, numMels)');
            return null;
        }
    }
}




export { BinSums, BinSumsMirrored, BinStacks, BinStacksMirrored, BinMeans, BinMeansMirrored, BinDeltas };
