import React, { useRef, useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import p5 from 'p5';
//eslint-disable-next-line
import loggit from '../../utils/Loggit.js';
import { getModelParams } from '../../utils/helpers.js';
import modelList from '../../settings/modelList.json';

// import { BreathUCS } from '../../components/breath/BreathUCS';

import AudioDataCapture from '../../hooks/AudioDataCapture.js'; // a custom hook

// import { MelVisMeterLateral } from "../../components/datavis/MelVisMeterLateral";   
import { LabTestSystem1 } from "../../components/datavis/LabTestSystem1.js";   
import { TensorflowModel } from '../../utils/Tensorflow.js';
import { MonophthongGong } from '../../components/datavis/MonophthongGong.js';

import img from '../../images/ember-particle.png';
import volutiaLogo from '../../images/volutia-logo.png';
import { ref } from 'yup';
import { model } from '@tensorflow/tfjs-layers';

function TriWizardModal ({isOpen, onClose}) {

    //<<<<< BEGIN AUDIO DATA CAPTURE SETTINGS

    // binSums/r50m40f4s1_b1_v1 ** excellent results so far, but the batch voices were just dasan and richard, plus we got lucky with normalization handling. new process doesn't line up with website
    // const tensorflowModelName.current = 'binSums/r50m40f4s1_b1_v1_MODEL-RELU000-SOFTMAX6'; // 9 responding strongly to all at high and low pitches, but still medding things up. 

    // ===== BIN SUMS =====

    // const tensorflowModelName.current = 'binSums/r50m40f4s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS14'; // 1 uu sort of responding

    // const tensorflowModelName.current = 'binSums/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS11'; // 0
    // const tensorflowModelName.current = 'binSums/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS15'; // 0
    // const tensorflowModelName.current = 'binSums/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS23'; // 1 ee

    // const tensorflowModelName.current = 'binSums/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS8'; // 0 
    // const tensorflowModelName.current = 'binSums/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS16'; // 0
    // const tensorflowModelName.current = 'binSums/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS17'; // 0
    // const tensorflowModelName.current = 'binSums/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS24'; // 0
    // const tensorflowModelName.current = 'binSums/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS26'; // 1 ee
    // const tensorflowModelName.current = 'binSums/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS29'; // 0
    
    // const tensorflowModelName.current = 'binSums/r50m40f8s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS20'; // 1
    // const tensorflowModelName.current = 'binSums/r50m40f8s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS25'; // 0
    
    // ===== BIN STACKS =====
    
    // const tensorflowModelName.current = 'binStacks/r50m40f2s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS24'; // 2 uu and ee somewhat responding but not well

    // const tensorflowModelName.current = 'binStacks/r50m40f3s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS20'; // 2 ee an aw somewhat responding


    // ===== BIN MEANS =====
    // const tensorflowModelName.current = 'binMeans/r50m40f4s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS23'; // 0

    // const tensorflowModelName.current = 'binMeans/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS9'; // 0
    // const tensorflowModelName.current = 'binMeans/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS12'; // 1
    // const tensorflowModelName.current = 'binMeans/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS13'; // 1
    // const tensorflowModelName.current = 'binMeans/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS16'; // 1
    
    // const tensorflowModelName.current = 'binMeans/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS9'; // 0
    // const tensorflowModelName.current = 'binMeans/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS14'; // 1
    // const tensorflowModelName.current = 'binMeans/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS19'; // 1
    // const tensorflowModelName.current = 'binMeans/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS23'; // 0

    // ===== BIN DELTAS =====
    // const tensorflowModelName.current = 'binDeltas/r50m40f4s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS19'; // 2 sporadically responding to a few, but not consistent or correct

    // const tensorflowModelName.current = 'binDeltas/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS9'; // 2 sporadically responding to several, but not consistent or correct
    // const tensorflowModelName.current = 'binDeltas/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS17'; // 1
    // const tensorflowModelName.current = 'binDeltas/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS20'; // 2 seemed to be responding to 3...aa ee oh just barely though
    // const tensorflowModelName.current = 'binDeltas/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS22'; // 1
    // const tensorflowModelName.current = 'binDeltas/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS25'; // 2 seemed to be responding to 3...aa ee oh just barely though
    
    // const tensorflowModelName.current = 'binDeltas/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6'; // 1
    // const tensorflowModelName.current = 'binDeltas/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS16'; // 2 low aa to ee... sort of
    // const tensorflowModelName.current = 'binDeltas/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS13'; // 0
    // const tensorflowModelName.current = 'binDeltas/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS17'; // 1
    // const tensorflowModelName.current = 'binDeltas/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS21'; // 0
    // const tensorflowModelName.current = 'binDeltas/r50m40f6s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS23'; // 1



   // ******** Trained with data with lots of duplicates (error in the conversion process) ********


//    const tensorflowModelName.current = 'binSums/r50m40f4s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS12'; // 8 responding to all, but still not great seems like only to low pitch and some higher pitches 
//    const tensorflowModelName.current = 'binSums/r50m40f4s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS13'; // 8

    //   const tensorflowModelName.current = 'binMeans/r50m40f4s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS19'; // 5 responding to all, but mixing up ee for aa and the others aren't that strong
    //   const tensorflowModelName.current = 'binMeans/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS22'; // 7 responding to all, but still not great seems like only to low aa

//    const tensorflowModelName.current = 'binStacks/r50m40f2s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS10'; // 6 responding to all, but kind of jumpy and not really strong
//    const tensorflowModelName.current = 'binStacks/r50m40f2s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS12'; // 6 responding to all, but kind of jumpy and not really strong
//  ** I'm thinking that the nature of these numbers being non-smoothed is causing the jumpy behavior... good argument for other methods

    // const tensorflowModelName.current = 'binDeltas/r50m40f4s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS16'; //  7 responding well to all, but still not great
    // const tensorflowModelName.current = 'binDeltas/r50m40f4s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS18'; // 6 responding to all, but not strongly

    // const tensorflowModelName.current = 'binDeltas/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS10'; // 1
    // const tensorflowModelName.current = 'binDeltas/r50m40f5s1_b1_MODEL-RELU128-RELU64-RELU32-SOFTMAX6-EPOCHS14'; // 5 responding to all, but mixing up ee for aa and the others aren't that strong
    // ***************************************************



    // const modelsAvailable = useRef([]);
    const tensorflowModelName = useRef('');
    const [displayTensorflowModel, setDisplayTensorflowModel] = useState('');

    const [tensorflow, setTensorflow] = useState(null);
    const predictions = useRef(Float32Array.from({length: 6}));
    
    const preFilter = 'none'; // prefilter the data before evaluating - default is none if not specified, just comment out this parameter
    // we use these to try and ignore sound signals irrelevant to the ACTIVATION SIGNAL the game is based on
    // 'none' is the default and will not apply any filters 
    // 'hipass' filter is used to filter out the lower frequencies which is where the power of the vocal chord signal lives (approximately 80-255dB range for humans)
    // 'midrange' filter is used to filter out the lower and higher frequencies, focusing on the signal activity in the middle  
    // 'lowpass' filter is used to filter out the higher frequencies which is where a lot of irrelevant noise can live
    const showOutliers = true; // false will filter out the utliers - default is true if not specified
    const showMelVis = true; // use mel spectrogram visualizations - default is false if not specified
    const modelName = useRef(null);
    const modelStructure = useRef(null);
    const modelOutputShape = useRef(null);
    const numMelBands = useRef(null);
    const frameWidth = useRef(null);
    const refreshRate = useRef(50);

    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [preloadComplete, setPreloadComplete] = useState(false);

    const {
        setConfigParameters,
        stopAudioDataCapture, 
        getAmbientNoiseLevel,
        getCurrentVolumeInDecibels, 
        getMelStructuredData,
        } =  AudioDataCapture();

    const [interval, setInterval] = useState(null);

    // useEffect to first load the settings when the modal is opened
    const loadSettings = () => {
        loggit.ghost('     TriWizardModal >>>>> Loading Settings');

        let { mname, mstruct, outshape, nmels, fw, rr, success } = getModelParams(tensorflowModelName.current);
        if (!success) closeModal();
        modelName.current = mname;
        modelStructure.current = mstruct;
        modelOutputShape.current = outshape;
        numMelBands.current = Number(nmels);
        frameWidth.current = Number(fw);
        refreshRate.current = Number(rr);
        setDisplayTensorflowModel( tensorflowModelName.current )
        
        //create an interval that checks if the settings are properly loaded
        setInterval(checkSettings, 50);
    }
    
    // useEffect to set the settingsLoaded flag when the settings are loaded
    const checkSettings = () => {
        if (numMelBands.current && frameWidth.current && modelStructure.current && !settingsLoaded) {
            loggit.ghost('     TriWizardModal >>>>> Settings Loaded');
            setSettingsLoaded(true);
        }
    };
    
    async function loadTensorflowModel() {
        if (!tensorflow) {
            const tf = new TensorflowModel(tensorflowModelName.current, modelStructure.current, numMelBands.current, frameWidth.current);
            setTensorflow(tf);
        } 
    }
    
    useEffect(() => {
        if (isOpen && settingsLoaded) {
            clearInterval(interval);

            // load the tensorflow model and make sure it's ready for predictions
            loggit.ghost('     TriWizardModal >>>>> Loading Tensorflow Model:', tensorflowModelName.current);
            loadTensorflowModel();
            
            setPreloadComplete(true);
        }
    }, [isOpen, settingsLoaded]);
    
    
    async function performPrediction(inputData) {
        // Optionally check if model is loaded or wait for it to load
        if (settingsLoaded) {
            let results = await tensorflow.makeVowelPrediction(inputData);
            if (results) predictions.current = results;

            loggit.ghost('     TriWizardModal >>>>> Prediction:', predictions.current);
        }
    }
    
    const sketchRef = useRef(null);
    const ambientNoiseLevel = useRef(null);
    const calibrationTimer = useRef(0);
    const myP5 = useRef(null);
    //eslint-disable-next-line
    const [gameEnv, setGameEnv] = useState(null); // we use this to access the game environment methods if we need it
    const fps = 60; // frameRate
    
    
    useEffect(() => {
        if (isOpen && sketchRef.current && preloadComplete) {

            // set the config parameters for the audio data capture
            setConfigParameters({preFilterArg: preFilter, 
                                showOutliersArg: showOutliers, 
                                reduceNoiseArg: false,
                                showMelVisArg: showMelVis, 
                                showMfccArg: false,
                                modelStructureArg: modelStructure.current, 
                                numMelBandsArg: numMelBands.current, 
                                frameWidthArg: frameWidth.current, 
                                refreshRateArg: refreshRate.current, 
                                preloadCompleteArg: preloadComplete});

            myP5.current = new p5(p => {
                let imgOpacity;
                let game;
                let game2;
                let game3;
                let monophthongAA;
                let monophthongEE;
                let monophthongAW;
                let monophthongOH;
                let monophthongUU;
                //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
                // define the canvas type here.... the coordinate system will provide x & y offset values for objects to use.
                // this is so that we can use the same objecgs within both P2D and WEBGL sketches
                p.canvasType = 'WEBGL'; // WEBGL or P2D
            
                //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

                const thisCanvasType = p.canvasType === 'WEBGL' ? p.WEBGL : p.P2D;
                loggit.ghost(`     TriWizardModal >>>>>> Preloading is Complete -> Be advised, p5 is loading this Canvas Type: ${thisCanvasType}`);
                
                p.preload = () => {
                    p.particleImage = p.loadImage(img);
                    p.volutiaLogo = p.loadImage(volutiaLogo);
                    p.messageFont = p.loadFont('/fonts/Comfortaa/Comfortaa-Bold.ttf');
                    p.successFont = p.loadFont('/fonts/Righteous/Righteous-Regular.ttf');
                };                
                
                p.setup = () => {
                    p.frameRate(fps);
                    const windowWidth = sketchRef.current.clientWidth;
                    const windowHeight = sketchRef.current.clientHeight;
                    
                    p.createCanvas(windowWidth, windowHeight, thisCanvasType);
                    p.xOffset =  p.canvasType === 'WEBGL' ? 0 : p.width/2;
                    p.yOffset =  p.canvasType === 'WEBGL' ? 0 : p.height/2;
                    
                    // &&&&&&&&&&&& Insert any global canvas customizations here &&&&&&&&&&&&&&&
                    p.angleMode(p.DEGREES);

                    // &&&&&&&&&&&&&&&&&&&&&&&&&&&
                    
                    p.background(0);
                    
                    game = new LabTestSystem1(p);
                    game2 = new LabTestSystem1(p);
                    game3 = new LabTestSystem1(p);
                    setGameEnv(game);

                    monophthongAA = new MonophthongGong(p, {iam: 'aa', innerColor: [218, 20, 19], outputShape: modelOutputShape.current});
                    monophthongAW = new MonophthongGong(p, {iam: 'aw', innerColor: [218, 20, 19], outputShape: modelOutputShape.current});
                    monophthongEE = new MonophthongGong(p, {iam: 'ee', innerColor: [218, 20, 19], outputShape: modelOutputShape.current});
                    monophthongOH = new MonophthongGong(p, {iam: 'oh', innerColor: [218, 20, 19], outputShape: modelOutputShape.current});

                    monophthongUU = new MonophthongGong(p, {iam: 'uu', innerColor: [218, 20, 19], outputShape: modelOutputShape.current});
                };
                
                let theta = 0;
                p.draw = () => {
                    if (!ambientNoiseLevel.current) {
                        // ENV LOADER - AUDIO CHECK for ambient noise level - get the ambient noise level and set it as the volume floor within the game
                        if (calibrationTimer.current < fps * 2) { // check for x number of seconds
                            calibrationTimer.current++;
                        } else {
                            ambientNoiseLevel.current = getAmbientNoiseLevel(); // returns null if not ready
                            loggit.ghost('     TriWizardModal >>>>> checking Ambient Noise Level:', ambientNoiseLevel.current);
                            if (ambientNoiseLevel.current !== null) {
                                game.calibrate(ambientNoiseLevel.current);
                                calibrationTimer.current = 0;
                            }
                        }
                        p.push(); // Isolate styling
                        p.translate(p.xOffset, p.yOffset);
                        p.textFont(p.messageFont);
                        p.textSize(20);
                        p.textAlign(p.CENTER, p.CENTER);
                        p.fill(255); // Set text color
                        p.text("CALIBRATING... please remain silent", 0, 0);
                        p.pop(); // Restore original styling
                    } else {
                        // run the game
                        p.clear();
                        p.background(0);
                        
                        let volume = getCurrentVolumeInDecibels();
                        let melSpectrogram = getMelStructuredData();

                        loggit.ghost('     TriWizardModal >>>>> User Volume:', volume, 'Mel Spectrogram:', melSpectrogram.length);
                        
                        // if volume is null, log an issue and skip the draw loop
                        if (volume === null) {
                            loggit.debug('Volume is null, skipping draw loop');
                            return;
                        }
                        // send melSpectrogram data to the tensorflow model for prediction                      
                        performPrediction(melSpectrogram);
                        
                        // draw the volutia logo
                        p.push();
                        p.translate(p.xOffset, p.yOffset, -400);
                        imgOpacity = p.map(volume, 35, 90, 5, 20);
                        p.tint(255, imgOpacity);
                        let newWidth = 800;
                        let newHeight = p.volutiaLogo.height * (newWidth / p.volutiaLogo.width);
                        p.image(p.volutiaLogo, -newWidth / 2 + 20, -newHeight / 2, newWidth, newHeight);
                        p.pop();
                        
                        p.push();
                        // p.rotateY(theta);
                        // p.rotateZ(90);
                        // p.smooth();
                        p.translate(0, 0, -50);
                        if (melSpectrogram.length > 0) {
                            p.push();
                            // p.rotateX(theta);
                            p.translate( -400, 0, 0);
                            game.update(volume, melSpectrogram); // update the game based on the user volume (breath as amplitude, melFrequency data, etc.)
                            game.run();
                            p.pop();
                            
                            p.push();
                            // p.rotateY(90);
                            // p.rotateX(-theta + 90);
                            p.translate(0, 0, -100);
                            game2.update(volume, melSpectrogram); // update the game based on the user volume (breath as amplitude, melFrequency data, etc.)
                            game2.run();
                            p.pop();

                            p.push();
                            // p.rotateY(90);
                            // p.rotateX(-theta + 90);
                            p.translate(400, 0, 0);
                            game3.update(volume, melSpectrogram); // update the game based on the user volume (breath as amplitude, melFrequency data, etc.)
                            game3.run();
                            p.pop();

                            // draw the monophthong gongs
                            let monOffset = 500
                            p.push();
                            p.translate(-monOffset,     monOffset, 0);
                            monophthongAA.update(predictions.current, volume);
                            monophthongAA.run();
                            p.pop();
                            p.push();
                            p.translate(-monOffset / 2, monOffset, 0);
                            monophthongEE.update(predictions.current, volume);
                            monophthongEE.run();
                            p.pop();
                            p.push();
                            p.translate(0,              monOffset, 0);
                            monophthongAW.update(predictions.current, volume);
                            monophthongAW.run();
                            p.pop();
                            p.push();
                            p.translate(monOffset / 2,  monOffset, 0);
                            monophthongOH.update(predictions.current, volume);
                            monophthongOH.run();
                            p.pop();
                            p.push();
                            p.translate(monOffset,      monOffset, 0);
                            monophthongUU.update(predictions.current, volume);
                            monophthongUU.run();
                            p.pop();


                        }
                        p.pop();
                        theta += 1;
                    }
                };
            }, sketchRef.current);
        }

        return () => {
            if (myP5.current) {
                myP5.current.remove();
                myP5.current = null;
            }
        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, preloadComplete]);
    
    const resetGame = () => {
        // gameEnv.reset(); // calls the reset method on the game environment
        resetAmbientNoiseLevels();
    }    

    const resetAmbientNoiseLevels = () => {
        ambientNoiseLevel.current = null;
        calibrationTimer.current = 0;
    }

    const handleLoadModel = (modelPath) => {
        loggit.ghostingOff();
        loggit.ghost('     TriWizardModal >>>>> Loading Model:', modelPath);
        tensorflowModelName.current = modelPath;
        loadSettings();
    }

    const closeModal = () => {
        tensorflowModelName.current = '';
        setSettingsLoaded(false);
        setPreloadComplete(false);
        stopAudioDataCapture();

        onClose();
    }


    if (!isOpen) { return null; }
    return (
        <Modal 
            show={isOpen}
            size="xl"
            fullscreen={true} 
            onHide={closeModal}
            className="ember-modal-body">

            <Modal.Header closeButton>
                <Modal.Title>TRI WIZARD 🔮</Modal.Title>
            </Modal.Header>
            {settingsLoaded ? (<>
                    <Modal.Body className="d-flex align-items-center justify-content-center">
                        <div ref={sketchRef} id="p5-canvas" style={{ width: '100%', height: '100%' }} />
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-end">
                        <div>Now Testing Model: {displayTensorflowModel}</div>
                        <Button variant="secondary" onClick={resetAmbientNoiseLevels}>CALIBRATE</Button>
                        <Button variant="secondary" onClick={resetGame}>RESET</Button>
                    </Modal.Footer></>
                ) : (
                    <Form>
                        <Form.Group controlId="modelSelector">
                            <Form.Label>Select Model</Form.Label>
                            <Form.Control
                                as="select"
                                value={tensorflowModelName.current}
                                onChange={(e) => handleLoadModel(e.target.value)}
                            >
                                <option value="">Select a Prediction Model</option>
                                {modelList
                                    .filter(model => model.rating >= 0) // Filter models with a score of 0 or higher
                                    .map((model, index) => (
                                    <option key={index} value={model.path}>
                                        {model.path}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        {/* <Button variant="primary" onClick={(e) => handleLoadModel(e.target.value)}>
                            Load Model
                        </Button> */}
                    </Form>
                )}
        </Modal>
    );


}

export default TriWizardModal;


