import React, { useRef, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

import p5 from 'p5';


const GloWormModal = ({ isOpen, onClose }) => {
    const sketchRef = useRef(null);
    const myP5 = useRef(null);
    let flowStrength = 1.5; // Adjust this to simulate increasing fluid flowing strength

    useEffect(() => {
        if (isOpen) {
            myP5.current = new p5(p => {
                const modalBody = document.getElementById('p5-canvas');
                const windowWidth = modalBody.clientWidth;
                const windowHeight = modalBody.clientHeight;
                const particles = [];
                let theta = 0;
                
                p.setup = () => {                    
                    p.frameRate(60);
                    p.createCanvas(windowWidth, windowHeight, p.WEBGL);

                    for (let i = 0; i < 1000; i++) {
                        particles.push(new foodParticles(p, p.random(windowWidth*4)-windowWidth, p.random(windowWidth*4)-windowWidth));
                    }
                }
                p.draw = () => {
                    p.clear();
                    p.background(0);
                    let seaLevel = 0;
                    let amplitude = 50 + flowStrength * 30; 
                    p.rotateZ(theta);
                    
                    let zoomOffset =  p.sin(p.noise((p.frameCount)*0.0025) * (p.PI*2)) * 250;
                    p.translate(-windowWidth/2, 0, -800 + zoomOffset);

                    // PARTICLES ------------------------------
                    for (let i = 0; i < particles.length; i++) {
                        particles[i].display();
                    }
                    let makeNewParticles = 0;
                    for (let i = particles.length-1; i >= 0; i--) {
                        if (particles[i].isDead()) {
                            particles.splice(i, 1);
                            makeNewParticles += 1;
                        }
                    }
                    for (let i = 0; i < makeNewParticles; i++) {
                        particles.push(new foodParticles(p, p.random(windowWidth*4)-windowWidth, p.random(windowWidth*4)-windowWidth));
                    }
                    // -----------------------------------------

                    // //================================================================================================
                    // // =========== SPINE ==============================================================================
                    // const parallelCount = 4;
                    // const parallelSpacing = 12;
                    // const lineWeight = 5;
                    
                    // const step = 25; //                     

                    // let thisPos = p.createVector(0, 0);
                    // let nextPos = p.createVector(0, 0);
                    
                    // for (let i = -parallelCount/2; i <= parallelCount/2; i++) {
                    //     let p1lw = p.map(i, 0, parallelCount, lineWeight, 0);
                        
                    //     p.noFill();
                    //     p.beginShape();
                    //     p.push();
                        
                    //     p.strokeWeight(p1lw);
                    //     p.stroke(255, 255, 255, 5)
                    //     for (let x = step * 9; x < windowWidth - step * 9; x+= step) {
                            
                    //         thisPos = p.createVector(x, oscillator(p, x) * amplitude);
                    //         nextPos = p.createVector(x+step, oscillator(p, x+step) * amplitude);
                            
                    //         // first we get the vector angle between the two points as theta2    
                    //         let theta2 = p.atan2(nextPos.y - thisPos.y, nextPos.x - thisPos.x);
                    //         // now we rotate that angle by 90 degrees to get the angle of the parallel lines
                    //         let thetaOffset = theta2 + p.PI/2;
                    //         let xOffset = p.cos(thetaOffset) * parallelSpacing * i;
                    //         let yOffset = p.sin(thetaOffset) * parallelSpacing * i;
                    //         let p1x= thisPos.x + xOffset;
                    //         let p1y = thisPos.y + yOffset;
                    //         // console.log('parallelCounting: ', i, x, 'p1x: ', p1x, 'p1y: ', p1y)
                    //         p.vertex(p1x, p1y);
                            
                    //         // mirrored parallel
                    //         let p2x= thisPos.x - xOffset;
                    //         let p2y = thisPos.y - yOffset;
                    //         p.vertex(p2x, p2y);
                    //     }

                    //     p.pop();
                    //     p.endShape();
                    // }
                    // // =========== END SPINE ==========================================================================

                    p.noFill();
                    p.noStroke();
                    for (let x = 0; x < windowWidth; x++) {
                        let y = seaLevel + oscillator(p, x) * amplitude;
                        // Add FoodBit
                        if (y < seaLevel - amplitude - 6 ) {
                            p.push();
                            let opacityMap = p.sin(p.map(x/2, 0, windowWidth/2, 0, p.PI));
                            let opacity = opacityMap * 40;
                            p.fill(255, 255, 255, opacity);
                            let ellipseSize = 25 * opacityMap
                            p.ellipse(x, y, ellipseSize, ellipseSize);
                            p.pop();
                        }
                    }

                    p.push();
                    p.noStroke();
                    p.fill(255, 255, 255, 5)
                    const circleCount = 300;
                    const circleSize = windowHeight / 3;
                    for (let i=0; i < circleCount; i++){
                        let xpos = p.noise(i*200) * windowWidth; // random placement along the x axis
                        
                        let sizeMap = p.sin(p.map(xpos, 0, windowWidth, 0, p.PI )); // return a value between 0 and 1 where 0 is at the edges and 1 is in the middle (cos would flip that)
                        let sizeOffset =  p.sin(p.noise((p.frameCount * 0.01) + i*100)) * 155; // add some noisy variety to the segments that moves with time
                        
                        let diameter = sizeMap * circleSize + sizeOffset; // sizeMap scales the circleSize from small at the edges to large in the middle & sizeOffset adds some noisy oscillation to the size
                        p.ellipse(xpos, oscillator(p, xpos) * amplitude, diameter);
                    }
                    p.pop();


                    let angularVel = p.sin(p.noise(p.frameCount*0.01) * (p.PI*2)) * 0.01
                    // console.log('angularVel: ', angularVel)
                    theta += angularVel ;
                } 
                
            }, sketchRef.current);
        } 
        return () => {
            if (myP5.current) {
                myP5.current.remove();
                myP5.current = null;
            }
        }

    }, [isOpen]);

    
    const oscillator = (p, x) => {
        // let's map the x position to the range of 0 to 2PI * 4 (which should show 4 waves)
        let xMapped = p.map(x, 0, p.windowWidth, 0, 2 * p.PI * 2);
        xMapped += p.frameCount / 30 + p.noise(p.frameCount * 0.01)*2; // this will make the waves move to the right

        const a = 0.5
        const b = 0.2
        // const x_fzd = (xMapped + p.frameCount/50) + a * p.sin(x) // HAPPY ACCIDENT using both xMapped and x here causes an oscillation around the main wave pattern which looks like DNA (oscillation within an oscillation)
        const x_fzd = (xMapped + p.frameCount / 30) + a * p.sin(xMapped) // modify the phase
        const y = - (p.cos(x_fzd) + b * p.sin(2 * x_fzd))
        return y
    }


    class foodParticles {
        constructor(p, x, y) {
            this.x = x;
            this.y = y;
            this.p = p;
            this.radius = p.random(12);
            this.opacityMap = this.p.random(100)/100;
            this.lifespan = p.random(60) + 30; // random lifespan between 4 and 8 seconds

            // let's give this some motion from right to left... with a random path length with a size/opacity adjust from the sin of the position along it's random path length
            // first let's define the random path length
            this.pathLength = this.p.random(800) + 500; // minimum path length of 100 pixels
    
            // now let's define the start and end positions
            this.totalLife = this.lifespan;
            this.startX = this.x;
            this.endX = this.x - this.pathLength;
            this.startY = this.y;
            this.endY = this.y + this.p.random(-500, 500); // add a little y variation so they're not all going in the same direction
        }
        display() {
            this.p.push();

            let lifePassed = this.totalLife - this.lifespan;
            let oscillatedOffset = this.p.sin(this.p.map(lifePassed, 0, this.totalLife, 0, this.p.PI));

            let opacity = this.opacityMap * 10;
            this.p.fill(255, 255, 255, opacity);

            let ellipseSize = this.radius * oscillatedOffset + 10
            let xpos = this.p.map(lifePassed, 0, this.totalLife, this.startX, this.endX);
            let ypos = this.p.map(lifePassed, 0, this.totalLife, this.startY, this.endY);
            this.p.ellipse(xpos, ypos, ellipseSize, ellipseSize);
            
            this.p.pop();
            
            //  let's create a little trail behind the particle
            this.p.push();
            this.p.fill(255, 255, 255, opacity);
            let trailSize = ellipseSize;
            for (let i = 0; i < 10; i++) {
                this.p.ellipse(xpos + 2 * i, ypos, trailSize, trailSize);
                trailSize *= 0.98;
            }
            
            this.p.pop();
            this.lifespan -= 1;
        }
        isDead() {
            return this.lifespan < 0;
        }
    }



    const handleClose = () => {
        onClose();
    };

    return (
        <Modal  
        show={isOpen}
        size="xl"
        fullscreen={true} 
        onHide={handleClose}
        className="ember-modal-body">
            <Modal.Header closeButton>
                <Modal.Title>GLOWORM</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <div ref={sketchRef} id="p5-canvas" style={{ width: '100%', height: '100%' }}/>
            </Modal.Body>
        </Modal>
    );
}

export default GloWormModal;