import loggit from '../../utils/Loggit.js'


import { VolutiaSystem } from '../VolutiaSystem.js';
import { MelVisExtruder } from './MelVisExtruder.js';   
import { MelVisRing } from './MelVisRing.js';

class LabTestSystem1 extends VolutiaSystem {
    constructor(p) {
        super(p, true); // set third parameter to false after the first successful instantiation - shows the default melVisRing1 in the middle when true
        this.p = p;
        loggit.ghost(`  <<<<< LabTestSystem1 Class is instantiated >>>>>>xOffset: ${this.p.xOffset}, yOffset: ${this.p.yOffset}`);

        this.melVisExtruder1 = new MelVisExtruder(this.p,  { xOffset:  this.p.xOffset - 400 + 200,
                                                            yOffset: this.p.yOffset - 1,
                                                            strokeWidth: 5,
                                                            width: 400,
                                                            height: 200
                                                        }); 
        this.melVisExtruder2 = new MelVisExtruder(this.p,  { xOffset:  this.p.xOffset - 400 + 200,
                                                            yOffset: this.p.yOffset - 1,
                                                            strokeWidth: 5,
                                                            width: 400,
                                                            height: -200
                                                        }); 
        this.melVisRing1 = new MelVisRing(this.p,  { xOffset: this.p.xOffset,
                                                    yOffset: this.p.yOffset + 200,
                                                    strokeWidth: 5,
                                                    width: 400,
                                                    height: -200,
                                                    arcAngleStart: 0,
                                                    arcAngleEnd: 180
                                                });
        this.melVisRing2 = new MelVisRing(this.p,  { xOffset: this.p.xOffset,
                                                    yOffset: this.p.yOffset - 200,
                                                    strokeWidth: 5,
                                                    width: 400,
                                                    height: -200,
                                                    arcAngleStart: 180,
                                                    arcAngleEnd: 0
                                                }); 
    }

    update(volume, melSpectrogram) {
        super.update(volume, melSpectrogram);
        // console.log('LabTestSystem1 update()');
        this.melVisExtruder1.update(melSpectrogram, this.windForce);
        this.melVisExtruder2.update(melSpectrogram, this.windForce);
        this.melVisRing1.update(melSpectrogram, this.windForce);
        this.melVisRing2.update(melSpectrogram, this.windForce);
    }

    run() {
        super.run();
        // console.log('LabTestSystem1 run()');
        this.p.push();
        this.p.rotateZ(90);
        this.melVisExtruder1.run();
        this.melVisExtruder2.run();
        this.p.pop();
        this.melVisRing1.run();
        this.melVisRing2.run();
    }


}

export { LabTestSystem1 };