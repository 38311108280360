import * as tf from '@tensorflow/tfjs';

//eslint-disable-next-line
import loggit from './Loggit.js'

export async function buildMelFilterBank (fftSize, melBands, sRate){

    const numMelBands = melBands; // 
    const sampleRate = sRate; //default value
    const windowSize = fftSize;
    const hopSize = windowSize / 2;
    const lowerEdgeHertz = 0;
    const upperEdgeHertz = sampleRate / 2;

    
    // create a linearly spaced array of numMelBands points between the lower and upper edge mel values
    let lowerEdgeMel = hertzToMel(lowerEdgeHertz);
    let upperEdgeMel = hertzToMel(upperEdgeHertz);
    let melSpacingCount = numMelBands + 2;
    // loggit.debug(`      >>>>> lowerEdgeMel: ${lowerEdgeMel}  upperEdgeMel: ${upperEdgeMel}`);
    // loggit.debug(`      >>>>> numMelBands: ${numMelBands}  sampleRate: ${sampleRate}  windowSize: ${windowSize}  hopSize: ${hopSize}  lowerEdgeHertz: ${lowerEdgeHertz}  upperEdgeHertz: ${upperEdgeHertz}`)
    let melBandSpacing = tf.linspace(lowerEdgeMel, upperEdgeMel, melSpacingCount);
    
    // convert to an array then convert the mel values back to hertz
    let melBandsArray = await melBandSpacing.array();
    // loggit.debug(`      >>>>> melBandsArray: ${melBandsArray}`);

    let melCenterpointsHz = melBandsArray.map(mel => melToHertz(mel));
    
    melBandSpacing.dispose();
    melBandsArray = null;

    let filterbank = Float32Array.from({length: (numMelBands * hopSize)});
    // console.log(`  hopSize: ${hopSize}  melCenterpointsHz: ${melCenterpointsHz}`);
    try {
        for (let i = 1; i < melCenterpointsHz.length - 1; i++) {
            // filterbank[i - 1] = new Float32Array(hopSize).fill(0); // no longer creating a 2d array.... can delete this once 1d implementation is done

            let lower = melCenterpointsHz[i - 1];
            let middle = melCenterpointsHz[i];
            let upper = melCenterpointsHz[i + 1];
            let length = upper - lower;
            let height = 2 / length;
            let leftSlope = height / (middle - lower);
            let rightSlope = height / (middle - upper);
            let herzBinWidth = upperEdgeHertz / hopSize ;
            for (let j = 0; j < hopSize; j++) {
                let weight = 0;
                let frequencyHz = j * herzBinWidth; // converting j to a width in herz value so we are aligned with the mel values (which are also converted to herz)
                if (frequencyHz <= middle) {
                    weight = leftSlope * (frequencyHz - lower);
                } else {
                    weight = height + (rightSlope * (frequencyHz - middle));
                }
                weight = Math.max(weight, 0);
                // if (weight > 0) console.log(`  i: ${i}  j: ${j}  weight: ${weight}`);
                filterbank[ (i - 1) * hopSize + j ] = weight; // storing 2d array as a 1d array to take advantage of float32Array processing speed
            };
        }; // filterbank is now a 2d array with weights for applying to hertz values
        // Now we have the triangular filterbank as a constant that we can use on every audio data event to convert the STFT into a mel spectrogram

        loggit.ghost('Created Mel Filterbank: ', filterbank);
    } catch (error) {
        loggit.error('Error creating Mel Filterbank: ', error);
    }

    return filterbank;
};

function hertzToMel (hertz) {
    return 2595 * Math.log10(1 + hertz / 700);
};
function melToHertz (mel) {
    return 700 * (Math.pow(10, mel / 2595) - 1);
};

// Calculate the Mel Spectrogram from the frequency data - NOTE: would have preferred to tuck this into the MelFiltersBank class, but it wasn't relaying data fast enough. kept getting undefined while waiting for a promise.
export function calculateMelSpectrogram (frequencyData, melFilterBank, numMelBands, fftSize) {
    if (melFilterBank.length < 1) {
        loggit.warning('WARNING     MelFilterBank >>>>> Mel Filter Bank is still empty');
        return [];
    } else {
        let melSpectrogram = Float32Array.from({length: numMelBands}); // create an empty array to hold the mel spectrogram
        let melEnergy = 0;

        // loggit.debug(`Mel melFilterBank: ${melFilterBank[39]} `);
        // loggit.debug(`Frequency Data: ${frequencyData} `);
        let hopSize = fftSize / 2;

        loggit.ghost(`      MelFilterBank lengths >>>>> frequencyData: ${frequencyData.length}  melFilterBank: ${melFilterBank.length}  hopSize: ${hopSize}  melFilterBank Binsize: ${melFilterBank.length / numMelBands}  numMelBands: ${numMelBands}`)
        if (((melFilterBank.length / numMelBands + frequencyData.length + hopSize) / 3) !== hopSize) { // all three should be the same...
            loggit.error(`<ERROR>       MelFilterBank lengths are not matching >>>>> frequencyData: ${frequencyData.length}  melFilterBank: ${melFilterBank.length}  hopSize: ${hopSize}  melFilterBank Binsize: ${melFilterBank.length / numMelBands}  numMelBands: ${numMelBands}`)
            return;
        }

        // multiply the frequencyData against each array in the melFilterBank to get the Mel Spectrogram
        for (let i = 0; i < numMelBands; i++) {
            // check that frequencyData and melFilterBank[i] are the same length
                // multiply the two arrays, then sum the result to get the mel energy
                for (let j = 0; j < hopSize; j++) {
                    melEnergy += melFilterBank[(i * hopSize) + j] * frequencyData[j]; // iterating through melFilterBank flattened 2d array 
                }
                melSpectrogram[i] = melEnergy;

                melEnergy = 0;
        }
        // Normalize the values to the range 0 to 1...  setting a fixed maxVal to normalize the tensor values across all sampled audio
        // QUESTION: is this a good idea? or should we normalize each audio data event separately? If it is a good idea, will this value work for all audio data events?

        loggit.ghost(`          MelFilterBank >>>>> DotProduct Mel Spectrogram Data: min> ${Math.min(...melSpectrogram)} max> ${Math.max(...melSpectrogram)}`);

        melEnergy = null;
        
        return melSpectrogram;
    }
};
