
//eslint-disable-next-line
import loggit from './Loggit.js'

//======== ARRAY TOOLS
class ArrayStatisticsTool {
    // constructor () {
    //     loggit.debug('                  -A new ArrayStatisticsTool is initiated');
    // }

    mean (array) {
        if (array.length === 0) return 0;
        return array.reduce((a, b) => a + b, 0) / array.length;
    }
    min (array) {
        return Math.min(...array);
    }
    max (array) {
        return Math.max(...array);
    }
    sum (array) {
        return array.reduce((a, b) => a + b, 0);
    }
    product (array) {
        return array.reduce((a, b) => a * b, 0);
    }
    variance (array) {
        let mean = this.mean(array);
        return this.mean(array.map(num => Math.pow(num - mean, 2)));
    }
    standardDeviation (array) {
        return Math.sqrt(this.variance(array));
    }
    median (array) {
        if (array.length === 0) return 0;
        array.sort((a, b) => a - b);
        let lowMiddle = Math.floor((array.length - 1) / 2);
        let highMiddle = Math.ceil((array.length - 1) / 2);
        return (array[lowMiddle] + array[highMiddle]) / 2;
    }
    removeOutliers (array, threshold = 3) {  // threshold is number of standard deviations deviations away from the mean to ignore
        if (array.length === 0) return [];
        let mean = array.reduce((a, b) => a + b) / array.length;
        let standardDeviation = this.standardDeviation(array);
        let altArray = array.filter(num => Math.abs(num - mean) < threshold * standardDeviation);
        // loggit.debug(` Removing Outliers: ${array.filter(num => Math.abs(num - mean) > threshold * standardDeviation)}`);
        return altArray;
    }
    normalizeArray (array, minmax = true) {
        if (array.length === 0) return new Float32Array(0);
        let min = Math.min(...array); // get the min value in the array (similar to what Librosa does for normalization)
        let max = Math.max(...array); // get the max value in the array (similar to what Librosa does for normalization)

        let normalizedArray = new Float32Array(array.length);
        for (let i = 0; i < array.length; i++) {
            if (minmax) {
                normalizedArray[i] = (array[i] - min) / (max - min);
            } else {
                normalizedArray[i] = (array[i] - min ) / (max - min);
            }
        }
        return normalizedArray;
    }

}



//======== VECTOR TOOLS
class VectorStatisticsTool {
    linearRegression(pointsArray) {
        const n = pointsArray.length;
        if (n < 2) {
            throw new Error('At least two points are required to calculate linear regression.');
        }

        let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0;

        for (const { x, y } of pointsArray) {
            sumX += x;
            sumY += y;
            sumXY += x * y;
            sumX2 += x * x;
        }

        const denominator = n * sumX2 - sumX * sumX;
        if (denominator === 0) {
            throw new Error('Cannot calculate linear regression due to zero denominator.');
        }

        const slope = (n * sumXY - sumX * sumY) / denominator;
        const intercept = (sumY - slope * sumX) / n;

        return { slope, intercept }; // y = mx + b
    }
}


//======== MATRIX TOOLS



export {ArrayStatisticsTool , VectorStatisticsTool } ;