import React, { useRef, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

import p5 from 'p5';


const WindSwellModal = ({ isOpen, onClose }) => {
    const sketchRef = useRef(null);
    const myP5 = useRef(null);
    
    useEffect(() => {
        if (isOpen) {
            myP5.current = new p5(p => {
                const modalBody = document.getElementById('p5-canvas');
                const windowWidth = modalBody.clientWidth;
                const windowHeight = modalBody.clientHeight;
                
                
                let windStrength = 1500; // Adjust this to simulate increasing wind strength
                let amplitude = 95; // Wind increases wave height
                let theta = 20;
                const nVertices = 12;
                const meshWidth = windowWidth * 2;
                const nSpacing = meshWidth / nVertices;
                const isSimplexGrid = true;
                

                let vertices = [];
                for (let i = 0; i < nVertices*2; i++) {
                    let column = [];
                    for (let j = 0; j < nVertices*2; j++) {
                        let xpos = i * nSpacing - meshWidth;
                        if (isSimplexGrid && j % 2 === 0) xpos += nSpacing/2;
                        let ypos = j * nSpacing - meshWidth;
                        // let's give some variation to the x and y positions to make it more interesting
                        xpos += p.sin(p.frameCount) * p.noise(xpos * 0.01) * 0.8;
                        ypos += p.sin(p.frameCount + 57) * p.noise(ypos * 0.01 + 845) * 1.2;
                        
                        column.push({x: xpos, y: ypos, z: 0, color: p.color(255, 255, 255)});
                    }
                    vertices.push(column);
                }
                console.log(vertices);
                // now each point in the grid has its x, y, z, and color property
                // when we update the mesh, we will update the z property of each point using the oscillator function

                const updateMesh = () => {
                    for (let i = 0; i < vertices.length; i++) {
                        for (let j = 0; j < vertices[i].length; j++) {
                            // adding some wind effect... noise along the y axis (with the oscillator wave action)
                            let yWind = p.sin(p.noise(vertices[i][j].y * 0.01) * p.PI) * windStrength;
                            vertices[i][j].z = oscillator(p, vertices[i][j].y) * amplitude ;

                            // add some wind effect along the x axis
                            let xWind = p.sin(p.noise(vertices[i][j].x * 0.01 + 254) * p.PI) * windStrength;
                            vertices[i][j].z += oscillator(p, vertices[i][j].x) * 15 + xWind + yWind; // add some oscillation based on the x position (small portion) and the xWind

                            // add some xpos wiggle
                            // vertices[i][j].x += p.sin(p.frameCount) * p.noise(vertices[i][j].x * 0.01) * 0.8;
                            // vertices[i][j].y += p.sin(p.frameCount + 57) * p.noise(vertices[i][j].y * 0.01 + 845) * 1.2;
                        }
                    }
                }

                p.setup = () => {                    
                    p.frameRate(60);
                    p.createCanvas(windowWidth, windowHeight, p.WEBGL);
                    // change to degree mode
                    p.angleMode(p.DEGREES);
                }
                p.draw = () => {
                    updateMesh(); // update the z values of the mesh with the oscillator function

                    p.clear();
                    p.background(0);

                    p.translate(-700, -300, -2800);
                    p.rotateX(55);
                    p.rotateY(0);
                    p.rotateZ(theta);

                    // DRAW MESH
                    p.strokeWeight(1);
                    p.noFill();
                    p.beginShape();
                    for (let i = 0; i < vertices.length - 1; i++) {
                        for (let j = 0; j < vertices[i].length - 1; j++) {
                            // console.log(vertices[i][j].z);

                            // pink (218, 20, 109, 255);
                            let colorMapR = p.map(vertices[i][j].y, 0, meshWidth, 80, 178) + p.map(vertices[i][j].z, -70, 70, -20, 60);
                            let colorMapG = p.map(vertices[i][j].y, 0, meshWidth, 0, 5) + p.map(vertices[i][j].z, -70, 70, 0, 50);
                            let colorMapB = p.map(vertices[i][j].y, 0, meshWidth, 40, 60) + p.map(vertices[i][j].z, -70, 70, 160, 70);

                            p.stroke(colorMapR, colorMapG, colorMapB, 255);
                            let v1 = vertices[i][j];
                            let v2 = vertices[i][j+1];
                            let v3 = vertices[i+1][j];
                            p.line(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z);
                            p.line(v1.x, v1.y, v1.z, v3.x, v3.y, v3.z);
                            p.line(v2.x, v2.y, v2.z, v3.x, v3.y, v3.z);
                        }
                    }
                    p.endShape();
                    // END DRAW MESH                    
                    
                    // theta += 0.05 ;
                } 
                
            }, sketchRef.current);
        } 
        return () => {
            if (myP5.current) {
                myP5.current.remove();
                myP5.current = null;
            }
        }

    }, [isOpen]);

    // 
    const oscillator = (p, y) => {
        // let's map the x position to the range of 0 to 2PI * 4 (which should show 4 waves)
        let zMapped = p.map(y, 0, p.windowHeight, 0, 2*p.PI * 30);
        // zMapped += p.frameCount / 30 + p.noise(p.frameCount * 0.01)*2; // this will make the waves move to the right
        zMapped += p.frameCount; // this will make the waves move to the right
        const a = 0.5
        const b = 0.2
        const y_fzd = zMapped + a * p.sin(zMapped) // modify the phase
        const z = - (p.cos(y_fzd) + b * p.sin(2 * y_fzd))
        return z
    }
    



    const handleClose = () => {
        onClose();
    };

    return (
        <Modal  
        show={isOpen}
        size="xl"
        fullscreen={true} 
        onHide={handleClose}
        className="ember-modal-body">
            <Modal.Header closeButton>
                <Modal.Title>WINDSWELL 🌊</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <div ref={sketchRef} id="p5-canvas" style={{ width: '100%', height: '100%' }}/>
            </Modal.Body>
        </Modal>
    );
}

export default WindSwellModal;