// Contains the physical simulated game world where the user’s avatar faces different challenges. 
// Some elements will be designed to inherently give the user feedback, 
// but we’ll also need some options for other visual feedback. outside of the sim.
// World Map (terrain)
// Obstacles
// Avatar
// Motivator
// Biofeedback

import loggit from '../../../utils/Loggit.js';

import Matter, { Common } from 'matter-js';
// import decomp from 'poly-decomp';




class SisyphusSimWorld {
    constructor(simWorldRef, config) {
        this.sceneRef = simWorldRef;
        this.config = {
            // custom parameters for the sim world
            bgColor: '#DAE9ED',
            ...config
        };

        // initialize the sim world elements
        loggit.ghost('Drivers start your engines! SisyphusGameSystem is running');

        var Engine = Matter.Engine,
            Render = Matter.Render,
            Runner = Matter.Runner,
            MouseConstraint = Matter.MouseConstraint,
            Mouse = Matter.Mouse,
            Composite = Matter.Composite,
            Bodies = Matter.Bodies,
            Body = Matter.Body;
            // Vertices = Matter.Vertices;
            // Common = Matter.Common;
            // Common.setDecomp(decomp);

        let windowWidth = this.sceneRef.current.clientWidth;
        let windowHeight = this.sceneRef.current.clientHeight;

        loggit.ghost('Window Width:', windowWidth, 'Window Height:', windowHeight);
        
        this.angularAcceleration = 0.00;

        this.engine = Engine.create();
        this.render = Render.create({
            element: this.sceneRef.current,
            engine: this.engine,
            options: {
                width: windowWidth,
                height: windowHeight,
                background: this.config.bgColor,
                // wireframeBackground: this.config.bgColor,
                wireframes: false,
                showAngleIndicator: false,
                showCollisions: false,
                showConvexHulls: true,
                friction: 0.01,
            }
        });
        loggit.ghost(`          SimWorld CREATED >>>>> Engine: ${this.engine}`);


        // Runner
        this.runner = Runner.create();
        Runner.run(this.runner, this.engine);

        // Add bodies, we might want to move this to a separate function
        this.world = this.engine.world;
        
        Composite.add(this.world, [
            Bodies.rectangle(windowWidth/2, 0, windowWidth, 50, { isStatic: true, render: {lineWidth: 0} }),
            Bodies.rectangle(windowWidth/2, windowHeight - 25, windowWidth, 50, { isStatic: true, render: {lineWidth: 0} }),
            Bodies.rectangle(windowWidth-25, windowHeight/2, 50, windowHeight, { isStatic: true, render: {lineWidth: 0} }),
            Bodies.rectangle(0, windowHeight/2, 50, windowHeight, { isStatic: true, render: {lineWidth: 0} })
            
        ]);
        
        // create an L shape where the height is 40 pixels and the width is 500 pixels and the thickness is 20 pixels, using vertices, then add to the world
        // Define the two parts of the L shape
        let longPiece = Bodies.rectangle(0, 0, 350, 20, { 
                isStatic: true, 
                chamfer: {radius: 2},
                render: {
                    fillStyle: '#000000', // Set the fill color
                    strokeStyle: '#333FFF', // Set the stroke color
                    lineWidth: 0 // Set the stroke width
                }
             }); // x  y  w  h
        let shortPiece = Bodies.rectangle(-167, -10, 16, 20, { 
                isStatic: true, 
                chamfer: {radius: 1},
                render: {
                    fillStyle: '#000000', // Set the fill color
                    strokeStyle: '#333FFF', // Set the stroke color
                    lineWidth: 0 // Set the stroke width
                }
            });
        let longPiece2 = Bodies.rectangle(0, 0, 600, 20, { 
                isStatic: true, 
                chamfer: {radius: 2},
                render: {
                    fillStyle: '#000000', // Set the fill color
                    strokeStyle: '#333FFF', // Set the stroke color
                    lineWidth: 0 // Set the stroke width
                }
             }); // x  y  w  h
        let shortPiece2 = Bodies.rectangle(-292, -12, 16, 24, { 
                isStatic: true, 
                chamfer: {radius: 1},
                render: {
                    fillStyle: '#000000', // Set the fill color
                    strokeStyle: '#333FFF', // Set the stroke color
                    lineWidth: 0 // Set the stroke width
                }
            });
        // Create a composite that includes both pieces
        this.LShape = Body.create({
            parts: [longPiece, shortPiece]
        });

        // Set the entire composite to be static
        Body.setStatic(this.LShape, true);
        Body.setPosition(this.LShape, { x: 300, y: 350 });
        // Rotate the composite about its center
        Body.rotate(this.LShape, -Math.PI * 0.07);
        // Add the L shape to the world
        Composite.add(this.world, [this.LShape]  );  
        
        this.Lshape2 = Body.create({
            parts: [longPiece2, shortPiece2]
        });
        Body.setStatic(this.Lshape2, true);
        Body.setPosition(this.Lshape2, { x: 290, y: 550 });
        Body.rotate(this.Lshape2, -Math.PI * 0.07);
        Composite.add(this.world, [this.Lshape2]  );        
        
        // see car function defined later in this file
        let scale = 1.1;
        Composite.add(this.world, this.newMotivator(60, 60, 150 * scale, 30 * scale, 30 * scale));

        Composite.add(this.world, [
            Bodies.rectangle(70, 120, 450, 40, { 
                    isStatic: true, 
                    angle: Math.PI * 0.18,
                    chamfer: {radius: 0},
                    render: {
                        fillStyle: '#3fc9ee', // Set the fill color
                        // strokeStyle: '#333FFF', // Set the stroke color
                        lineWidth: 0 // Set the stroke width
                    }
                }),

            Bodies.rectangle(700, 360, 700, 20, { isStatic: true, angle: -Math.PI * 0.07, chamfer: {radius: 10}, render: { fillStyle: '#060a19' }}),
            // Bodies.rectangle(windowHeight/2, 560, windowHeight, 20, { isStatic: true, angle: Math.PI * 0.04, render: { fillStyle: '#060a19' }})
        ]);
    

        // Mouse Control setup
        let mouse = Mouse.create(this.render.canvas);
        let mouseConstraint = MouseConstraint.create(this.engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false
                }
            }
        });

        Composite.add(this.world, mouseConstraint);
        this.render.mouse = mouse;

        // Start rendering
        Matter.Render.run(this.render);

    }


    update(mechForce) {
        // apply the force to the angular acceleration - ideal acceleration is 0.05
        this.angularAcceleration = mechForce < 0 ? 0 : mechForce; // only apply the force if it is greater than 0
        loggit.ghost('          SimWorld UPDATING >>>>> mechForce:', this.angularAcceleration);
        // Matter.Body.setAngularVelocity(this.avatarCar, this.angularAcceleration);
    }

    destroy() {
        // clear out any large parameters
        this.stopSimWorld();
    }

   
    stopSimWorld() {
        // stop the sim world
        loggit.ghost('          SimWorld STOPPED');

        Matter.Events.off(this.engine, 'beforeUpdate', () =>{} );
        Matter.Render.stop(this.render);
        Matter.Runner.stop(this.runner);
        Matter.Composite.clear(this.world);
        Matter.Engine.clear(this.engine);
        this.render.canvas.remove();
        this.render.canvas = null;
        this.engine = null;
        this.render = null;
    }

    
    // ====== Build a Car function ======
    newMotivator (xx, yy, width, height, wheelSize) {
        var Body = Matter.Body,
            Bodies = Matter.Bodies,
            Composite = Matter.Composite,
            Constraint = Matter.Constraint,
            Events = Matter.Events;
    
        var group = Body.nextGroup(true),
            wheelBase = 20;
    
        var wheel = Bodies.circle(xx, yy, wheelSize, { 
            collisionFilter: {
                group: group},
                density: 0.0002,
                friction: 0.8,
                restitution: -0.5,
                render: {
                    fillStyle: '#333333', // Set the fill color
                    strokeStyle: '#999999', // Set the stroke color
                    lineWidth: 2, // Set the stroke width
                }
            });

        var bar = Bodies.rectangle(xx, yy, wheelBase * 1.6180339, 8, {
            // angle: Math.PI * 0.5,
            render: {
                fillStyle: '#888888', // Set the fill color
                lineWidth: 0, // Set the stroke width
            }
        });

        var wheelBar = Body.create({
            parts: [wheel, bar]
        });
            
            
        var motivator = Composite.create({ label: 'Motivator' }), body = wheelBar;

        // Regularly apply an angular force to the rectangle
        Events.on(this.engine, 'beforeUpdate', () => {
            // Apply an angular force based on user input
            let wheelVelocity = Body.getAngularVelocity(wheelBar) + this.angularAcceleration;
            Body.setAngularVelocity(wheelBar, wheelVelocity);
        });


        Composite.addBody(motivator, wheelBar);

        
        return motivator;
    };

  
 
};

export {SisyphusSimWorld};
