
//eslint-disable-next-line
import loggit from '../../utils/Loggit.js'

class MelVisArcWing {
    constructor(p, options = {}) {
        this.p = p;
        this.options = {
            xOffset: 0,
            yOffset: 0,
            ringDirection: -1,
            inverted: false,
            diameter: 700,
            ...options
        }
        this.melSpectrogram = [0]
        
        this.defaultNoiseLevel = process.env.REACT_APP_DEFAULT_AMBIENT_NOISE_DBSPL; // calculated dBFS level
        this.ambientNoiseLevel = this.defaultNoiseLevel;
        this.windForce = this.p.createVector(0, 0);
        
        this.radius = this.options.diameter / 2;
        this.strokeWidth = 5;
        this.ringSpacing = 0;

        let theta = this.p.PI / 2;
        if (this.options.ringDirection === -1){
            this.arcAngleStart = -theta;
            this.arcAngleEnd = theta;
        } else if (this.options.ringDirection === 1){
            this.arcAngleStart = theta;
            this.arcAngleEnd = -theta;
        }
        
        this.ringbits = [];
        this.ringbitWidth = 5;
    }

    update(melSpectrogram, windForce){
        this.ringSpacing = this.radius / melSpectrogram.length;
        
        this.melSpectrogram = melSpectrogram;
        if (this.options.ringDirection === 1) this.melSpectrogram = [...melSpectrogram].reverse();
        
        this.windForce = windForce;

        for (let ringbit of this.ringbits){
            ringbit.applyUserInput(this.windForce);
        }
    }
    
    run(){
        // render the power line
        this.render();

        // console.log('Ringbits:', this.ringbits.length);
        this.ringbits = this.ringbits.filter(ringbit => !ringbit.isDead());

        for (let ringbit of this.ringbits){
            ringbit.render();
        }
    }

    render(){
        this.p.push();
        for (let i = 0; i < this.melSpectrogram.length; i++){
            let x = 0;
            let y = 0;
            let ringDiameter = this.ringSpacing * i;
            let strokeWidth = this.strokeWidth * this.melSpectrogram[i];

            let opacity = this.melSpectrogram[i] * 255;

            this.p.noFill();
            this.p.stroke(218, 20, 19, opacity);
            this.p.strokeWeight(strokeWidth);
            this.p.strokeCap(this.p.SQUARE);
            this.p.arc(x + this.options.xOffset, y + this.options.yOffset, ringDiameter, ringDiameter, this.arcAngleStart, this.arcAngleEnd); // arc(x, y, w, h, start, stop, [mode], [detail])

            // add a ringbit for each mel band ring, set it at a negative diameter (y)
            if (i > 0 && this.melSpectrogram[i] > 0.15){
                let ringbitX = -this.ringbitWidth + this.options.xOffset - 1;
                let ringbitY = - ringDiameter/2 - strokeWidth + this.options.yOffset + 1.5;
                // adjust for if the ringbits are inverted... default is false, which means the ringbits appear on the bottom half of the ring
                if (this.options.inverted) ringbitY += ringDiameter;
                // adjust for direction the ringbits are moving
                if (this.options.ringDirection === 1){ // adjust the ringbit positions if the ring is moving to the right
                    ringbitX += 6;
                };
                let width = this.ringbitWidth + 1;
                let height = strokeWidth; // this is the rect height which should be same as ring strokeWidth
                let color = this.p.color(218, 20, 19, opacity);
                let melVal = this.melSpectrogram[i];
                this.ringbits.push(new RingBit(this.p, ringbitX, ringbitY, width, height, color, melVal, ringDiameter, this.ringbitWidth, this.options.ringDirection));
            }
        }
        this.p.pop();
    }

};

class RingBit {
    constructor(p, x, y, width, height, color, melVal, diameter, ringbitWidth, ringDirection){
        this.p = p;
        this.position = this.p.createVector(x, y);
        this.width = width;
        this.height = height;
        this.color = color;
        this.melVal = melVal;
        this.diameter = diameter;
        this.ringbitWidth = ringbitWidth;
        this.ringDirection = ringDirection;

        this.acceleration = this.p.createVector(0.01, 0);
        this.velocity = this.p.createVector((this.ringbitWidth * 1.2), 0); // set the initial velocity
        this.velocity.mult(this.ringDirection); // reverse the velocity if the ring is moving to the right ringDirectio is -1 or 1

        this.lifespan = 1000;
        this.decayRate = 1;

    }   
    
    applyUserInput(f){
        // apply user input to the ringbit
        if (this.ringDirection === 1) this.acceleration.mult(-1); // reverse the acceleration

        // apply wind differently based on the ring direction
        if (this.ringDirection === 1 && this.lifespan < 995){  // 980 for shorter tail
            this.acceleration.add(f);
            this.velocity.add(this.acceleration);
            
            this.acceleration.mult(0); // reset the acceleration
        } else if (this.ringDirection === -1 && this.lifespan < 980){ // 950 for longer tail
            this.acceleration.add(f);
            this.velocity.add(this.acceleration);
            
            this.acceleration.mult(0); // reset the acceleration
        }
        this.position.add(this.velocity);
            
        this.lifespan -= this.decayRate;
    }

    render(){
        let x = this.position.x;
        let y = this.position.y;
        let widthModifier = 1.0;
        this.p.fill(this.color);
        this.p.noStroke();
        this.p.rect(x, y, this.width * widthModifier, this.height);

    }

    isDead(){
        if (this.p.canvasType === 'WEBGL') {
            if (this.position.x < -this.p.width/2 + 10 || 
                this.position.x > this.p.width/2 - 10 || 
                    this.position.y < -this.p.height/2 + 10 || 
                    this.position.y > this.p.height/2 - 10 || 
                        this.lifespan <= 0){
                // console.log(`I died at ${this.position.x}, ${this.position.y}`);
                return true;
            } else {
                return false;
            }
        } else {
            if (this.position.x < 10 || 
                this.position.x > this.p.width - 10 || 
                    this.position.y < 10 || 
                    this.position.y > (this.p.height - 10) || 
                        this.lifespan <= 0){
                // console.log(`I died at ${this.position.x}, ${this.position.y}`);
                return true;
            } else {
                return false;
            }
        }
        
    }
}


export { MelVisArcWing };