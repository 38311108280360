// ScoreKeeper Environment to handle the scorekeeping and display of scores

//eslint-disable-next-line
import loggit from '../../../utils/Loggit.js'

import { VectorStatisticsTool } from '../../../utils/StatisticsHelpers.js';

class ScoreKeeperEnvironment {
    constructor(p) {
        this.p = p;
        this.screenWidth = this.p.windowWidth;
        this.screenHeight = this.p.windowHeight;
        this.scores = [];
        this.vectorStats = new VectorStatisticsTool();
    }

    addScore(scoreValue) {
        let scoreId = this.scores.length + 1;
        this.scores.push(new Score(this.p, scoreId, scoreValue));
        let scoreCount = this.scores.length;
        loggit.debug('Score Added:', scoreValue);

        for (let score of this.scores) {
            score.calculateAnchorPosition(scoreCount);
        }
    }

    update() {
        for (let score of this.scores) {
            score.update();
        }
    }
    
    run() {
        this.render();
    }

    reset() {
        this.scores = [];
    }

    render() {
        if (this.scores.length === 0) return; // skip rendering if there are no scores to render

        let rightBuffer = 100;
        let rightAnchorWidth = 45;
        let dashWidth = 3;
        
        // run through each score in scores and get the xpos and ypos
        this.points = []
        let averageY = 0;

        let leftAnchor = this.p.createVector(0, 0);
        this.points.push(leftAnchor);
        
        for (let score of this.scores) {
            this.points.push(score.position);
            averageY += score.position.y;
        }
        
        averageY = Math.floor(averageY / this.scores.length);
        let rightAnchor = this.p.createVector(this.screenWidth - rightBuffer, averageY);
        this.points.push(rightAnchor);
        
        // render a line between each of the score points
        this.p.push(); //>>>>>>>>>> Isolate the drawing context

        this.p.translate(-this.screenWidth/2, this.screenHeight/2 - 60);
        this.p.stroke(63, 201, 231, 150);
        this.p.strokeWeight(1);
        for (let i = 0; i < this.points.length - 1; i++) {
            let point = this.points[i];
            let nextPoint = this.points[i + 1];
            this.p.line(point.x, point.y, nextPoint.x, nextPoint.y);
        }    
        // place the points on the line
        this.p.stroke(255, 255, 255, 255);
        this.p.strokeWeight(1);
        this.p.fill(100, 100, 100, 0);
        for (let i = 0; i < this.points.length; i++) {
            if (i === this.points.length - 1) { // draw a special anchor on the right side
                this.p.stroke(218, 20, 109, 255);
                this.p.strokeWeight(2);
                this.p.ellipse(this.points[i].x, this.points[i].y, 12, 12);
                this.p.strokeWeight(1);
                let dashit = true; // use this to alternate the dash pattern
                for (let j = 0; j < rightAnchorWidth; j += dashWidth) {
                    if (dashit) {
                        this.p.line(this.points[i].x - rightAnchorWidth/2 + j, this.points[i].y, this.points[i].x - rightAnchorWidth / 2 + j + dashWidth, this.points[i].y);
                        dashit = false;
                    } else {
                        dashit = true;
                    }
                }
            } else {
                this.p.ellipse(this.points[i].x, this.points[i].y, 12, 12);
            }
        }
        // draw a best fit line through the points using linear regression
        // first let's remove the first and last points which are just visual anchors for the previous plots
        this.points.shift();
        this.points.pop();
        
        if (this.points.length > 2) {
        
            let { slope, intercept } = this.vectorStats.linearRegression(this.points);
            let bestFitLine = [];
            for (let i = 0; i < this.points.length; i++) {
                let x = this.points[i].x;
                let y = slope * x + intercept;
                bestFitLine.push(this.p.createVector(x, y));
            }
            this.p.stroke(218, 20, 109, 255);
            this.p.strokeWeight(5);
            this.p.line(bestFitLine[0].x, bestFitLine[0].y, bestFitLine[this.points.length - 1].x, bestFitLine[bestFitLine.length - 1].y);
            
            this.p.pop(); //<<<<<<<<<< Restore the drawing context
        }
    }
}
//-----------------------------------------------------------


class Score {
    constructor(p, scoreId, scoreValue) {
        this.p = p;
        this.scoreId = scoreId;
        this.scoreValue = scoreValue;
        // loggit.debug('Score ID: ', this.scoreId, '  Score Value:', this.scoreValue);

        // positioning parameters
        this.screenWidth = this.p.windowWidth;
        this.screenHeight = this.p.windowHeight;
        this.bufferWidth = 200; // for left and right buffers
        this.scoreSpacingY = 25; // for vertical height (calculate based on percentage of screen height)
        let x = this.screenWidth;
        let y = this.scoreValue * this.scoreSpacingY;
        this.position = this.p.createVector(x, y);
        this.acceleration = this.p.createVector(0, 0);
        this.velocity = this.p.createVector(0, 0);
        this.myMass = this.p.random(1, 2);
        this.springForce = 0.12; // spring force constant
        this.damping = 0.85; // damping constant

        this.anchorPosition = this.p.createVector(0, 0);
        this.anchorMass = 300;

    }
   
    update() {
        // calculate the gravitational force exerted on this point by the anchor
        let distance = this.anchorPosition.copy();
        distance.sub(this.position);
        let force = distance.mult(this.springForce); // Hooke's Law
        this.velocity.add(force);
        this.velocity.mult(this.damping);
        this.position.add(this.velocity);

        this.acceleration.mult(0); // reset the acceleration
    }

    calculateAnchorPosition(scoreCount) {
        // calculate the anchor position based on the scoreId
        let scoreSpaceWidth = this.screenWidth - this.bufferWidth * 2;
        let scoreSpacing;
        if (scoreCount === 1) {
            scoreSpacing = scoreSpaceWidth/2;
        } else {
            scoreSpacing = scoreSpaceWidth / (scoreCount +1);
        }

        this.anchorPosition.x = Math.floor(this.bufferWidth + scoreSpacing * this.scoreId);
        this.anchorPosition.y = -Math.floor(this.scoreSpacingY * this.scoreValue) - 40;

    }
}


export { ScoreKeeperEnvironment };