import React from 'react';
import '../App.css';

const LogoutButton = () => {
  const handleLogoutClick = () => {
      // Delete the JWT token cookie
      console.log("Logging out...");
      document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.reload();
  };

  return (
    <button className='btn btn-link text-dark' onClick={handleLogoutClick}>Logout </button>
  );
};

export default LogoutButton;
