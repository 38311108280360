
//eslint-disable-next-line
import loggit from '../../utils/Loggit.js'

class MelVisBase {
    constructor(p, options = {}) {
        // loggit.debug(`  <<<<< A new MelVisBase is instantiated >>>>>>`);
        this.p = p;
        this.options = {
            xOffset: 0,
            yOffset: 0,
            strokeWidth: 5,
            width: 100,
            height: 100,
            ...options
        }

        this.defaultNoiseLevel = process.env.REACT_APP_DEFAULT_AMBIENT_NOISE_DBSPL; // calculated dBFS level
        this.ambientNoiseLevel = this.defaultNoiseLevel;
        
        this.melSpectrogram = [0]
        this.windForce = this.p.createVector(0, 0);
        this.spacing = 0;

        // customizable parameters
        
    }

    update(melSpectrogram, windForce){
        this.melSpectrogram = melSpectrogram;
        this.windForce = windForce;

        this.spacing = this.options.height / this.melSpectrogram.length;
        // loggit.debug(`   >>>>> spacing is: ${this.spacing}`)
    }
    
    run(){
        // render the power line
        this.render();
    }

  

};


export { MelVisBase };