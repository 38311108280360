// Contains multiple P5 elements which accepts the raw audio input 
// as well as sim updates about game progress. 
// May or may not utilize user feedback elements (depending on the game).
// Biofeedback
// Scorekeeper
// Game Progress

import loggit from '../../../utils/Loggit.js';

import p5 from 'p5';

class SisyphusUserFeedback {
    constructor(userFeedbackRef) {
        this.userFeedbackRef = userFeedbackRef;
    }

    update(data) {
        // update the user feedback elements
        loggit.ghost('          UserFeedback UPDATING >>>>> data:', data);
    }

        // // Initialize p5.js
        // new p5((sketch) => {
        //     sketch.setup = () => {
        //         sketch.createCanvas(800, 600);
        //         sketch.clear();
        //     };

        //     sketch.draw = () => {
        //         sketch.clear();
        //         sketch.fill(255, 0, 0);
        //         sketch.ellipse(box.position.x, box.position.y, 80, 80);
        //     };
        // }, p5Ref.current);


};

export {SisyphusUserFeedback};
