
// Custom logging utility to manage logging in different environments

class Loggit {
    constructor () {
        this.currentEnv = process.env.NODE_ENV;

        this.isGhosting = true;
    }

    ghostingOff = () => {
        this.isGhosting = false;
    }

    ghost = (...params) => {
        if (this.isGhosting) {
            // log nothing - uncomment to turn off ghosting and see everything... and I mean everything.
            // console.log(...params);
            return;
        } else {
            // log the params
            if (this.currentEnv !== 'production') {
                console.log(...params);
            }
            // turn ghosting back on automatically
            this.isGhosting = true;
        }
    }

    debug = (...params) => {
        if (this.currentEnv !== 'production') {
            console.log(...params);
        }
    }
    warning = (...params) => {
        if (this.currentEnv !== 'production') {
            console.log(...params);
        }
    }
    error = (...params) => {
        if (this.currentEnv !== 'production') {
            console.log(...params);
        }
    }
    info = (...params) => {
        // always log info
        console.log(...params);
    }

    oi = () => {
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Oi!!!!!!!!');
    }

};

const loggit = new Loggit();
// Object.freeze(loggit);
export default loggit;

