// This React Hook is for all the voice games and should handle data only
// this hook converts the user audio data into the game mechanics
// it should not handle any graphics or user feedback

// children classes handle the game sim graphics and user feedback visualizations
// ${title}SimWorld.js contains the Matter.js physics engine and the game mechanics
//      callback function simWorldUpdate() is passed to the SimWorld for it to send data back to the GameSystem
//      simWorldRef.current is passed to the UserFeedback class to allow it to access the dom element in the modal
//      custom parameters are passed to the SimWorld class to customize the configuration for each game

// ${title}UserFeedback.js contains the player messaging, feedback, and scoring
//      custom parameters are passed to the UserFeedback class to customize the feedback for each game
//      userFeedbackRef.current is passed to the SimWorld class to allow it to access the dom element in the modal 

// This class instantiates the SimWorld and UserFeedback classes with their respective configuration parameters
// once set up, this page also passes the data to each of them 
import React, { useRef, useState, useEffect } from 'react';
import loggit from '../../utils/Loggit.js';

import { SisyphusSimWorld } from './sisyphus/SisyphusSimWorld.js';
import { SisyphusUserFeedback } from './sisyphus/SisyphusUserFeedback.js';

const VoiceGameSystem = ({  simWorldRef = null,
                            userFeedbackRef = null
                        }) => {
    // set up the configuration parameters for the SimWorld and UserFeedback classes
    
    const [systemIsReady, setSystemIsReady] = useState(false);

    const simWorld = useRef(null);
    const simWorldConfig = {
        // custom parameters for the sim world
        // bgColor: '#333333',
    };
    const userFeedback = useRef(null);

    const volumeData = useRef(-1);
    const melSpectrogramData = useRef([]);

    // Define the Force that will be applied to the SimWorld
    const mechForce = useRef(0); // Game Mechanics Force >> mechForce

    // create a function that will calculate the force that should be applied to the SimWorld based on the user volume and or melSpectrogram data
    const calculateForce = (volume) => { // for now, we will just use the volume data to calculate the force
        // calculate the force
        let minVolume = 40; // ambient noise value!!!
        let maxVolume = 100;
        let maxAcceleration = 0.014;
        mechForce.current = (volume-minVolume)/(maxVolume-minVolume) * maxAcceleration;
    }


    useEffect(() => {
        if (systemIsReady) {
            loggit.debug(`          GameSystem is Ready! - SPINNING UP SimWorld and UserFeedback >>>>> simWorldRef: ${simWorldRef.current}     userFeedbackRef: ${userFeedbackRef.current}`)
            
            // TECHNICAL DEBT: create a setup function with a switch statement to load different games based on a parameter sent from the modal
            simWorld.current = new SisyphusSimWorld(simWorldRef, simWorldConfig);
            userFeedback.current = new SisyphusUserFeedback(userFeedbackRef);
        }
    }, [systemIsReady]);           
    
    const updateSimWorld = (data) => {
        if (simWorld.current) {
            // Logic for processing the data and updating the SimWorld Class
            simWorld.current.update(data);
        }
    }

    const updateUserFeedback = (data) => {
        if (userFeedback.current) {
            // Logic for processing the data and updating the UserFeedback class
            userFeedback.current.update(data);
        }
    }
                

    //===== EXPOSED FUNCTIONS =====

    const setSystemAudioData = (volume, melSpectrogram) => {
        loggit.ghost('          GameSystem RECEIVING new data >>>>> User Volume:', volume, 'Mel Spectrogram:', melSpectrogram.length);
        // set the system audio data
        volumeData.current = volume;
        melSpectrogramData.current = melSpectrogram;

        // calculate the force to be applied to the SimWorld
        calculateForce(volume);

        if (simWorld.current && userFeedback.current) {
            loggit.ghost(`          GameSystem UPDATING SisWorld and UserFeedback`);
            let newData = {
                volumeData: volumeData.current,
                melSpectrogramData: melSpectrogramData.current
            }
            updateSimWorld(mechForce.current);
            updateUserFeedback(newData);
        }
        
    }

    const setSystemNoiseLevel = (noiseLevel) => {
        loggit.ghost(`          GameSystem CALIBRATED >>>>> Ambient Noise Level: ${noiseLevel}`);
    }

    const shutdownGameSystem = () => {
        // shut down the game system
        if(simWorld.current) {
            simWorld.current.destroy();
            simWorld.current = null;
        }
        userFeedback.current = null;
        setSystemIsReady(false);
    }


    const pauseGame = () => {
        // pause the game
    }

    const resumeGame = () => {
        // resume the game
    }


    return {
        setSystemAudioData,
        setSystemNoiseLevel,
        setSystemIsReady,
        shutdownGameSystem,
        pauseGame,
        resumeGame
    }


    
}

export default VoiceGameSystem;
