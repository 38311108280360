/**
 * This REACT MODAL is a DataVis Playground
 */
import React, { useRef, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import p5 from 'p5';
//eslint-disable-next-line
import loggit from '../../utils/Loggit.js';
import { ArrayStatisticsTool } from '../../utils/StatisticsHelpers.js';

// import { BreathUCS } from '../../components/breath/BreathUCS';

import AudioDataCapture from '../../hooks/AudioDataCapture.js'; // a custom hook

// import { MelVisMeterLateral } from "../../components/datavis/MelVisMeterLateral";   
import { MelVisSerpentSystem } from "../../components/datavis/MelVisSerpentSystem.js";   

import img from '../../images/ember-particle.png';
import volutiaLogo from '../../images/volutia-logo.png';
import { log } from '@tensorflow/tfjs-core/dist/log.js';

function SerpentineModal ({isOpen, onClose}) {
    const ArrayStats = new ArrayStatisticsTool();

    const sketchRef = useRef(null);
    const ambientNoiseLevel = useRef(null);
    const calibrationTimer = useRef(0);
    const myP5 = useRef(null);
    //eslint-disable-next-line
    const [gameEnv, setGameEnv] = useState(null); // we use this to access the game environment methods if we need it
    const fps = 60; // frameRate
    
    const preFilter = 'none'; // prefilter the data before evaluating - default is none if not specified, just comment out this parameter
    // we use these to try and ignore sound signals irrelevant to the ACTIVATION SIGNAL the game is based on
    // 'none' is the default and will not apply any filters 
    // 'hipass' filter is used to filter out the lower frequencies which is where the power of the vocal chord signal lives (approximately 80-255dB range for humans)
    // 'midrange' filter is used to filter out the lower and higher frequencies, focusing on the signal activity in the middle  
    // 'lowpass' filter is used to filter out the higher frequencies which is where a lot of irrelevant noise can live
    const showOutliers = true; // false will filter out the utliers - default is true if not specified
    const showMelVis = true; // use mel spectrogram visualizations - default is false if not specified
    const showMfcc = false; // use mfcc visualizations - default is false if not specified
    // const modelName = useRef(null);
    const modelStructure = useRef('binSums');
    // const modelOutputShape = useRef(null);
    const numMelBands = useRef(null);
    const frameWidth = useRef(2);
    const refreshRate = useRef(50);

    // we use these when we want to load a prediction model for the melSpectrogram data, but since we are not using that, just set them to true
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [ preloadComplete, setPreloadComplete ] = useState(false);
    
    const {
        setConfigParameters,
        stopAudioDataCapture, 
        getAmbientNoiseLevel,
        getCurrentVolumeInDecibels,
        getMelStructuredData
        } =  AudioDataCapture();

    const loadSettings = () => {
        numMelBands.current = process.env.REACT_APP_DEFAULT_NUM_MEL_BANDS;
        setInterval(checkSettings, 50);
    }

    const checkSettings = () => {
        if (numMelBands.current !== null) {
            setSettingsLoaded(true); 
        }
    };
    
    useEffect(() => {
        if (isOpen && !settingsLoaded) {
            loadSettings();
        } else if (isOpen && settingsLoaded) {
            setPreloadComplete(true); // this triggers the main sketch to load where the audiodata capture is initiated and the game is started
        }
    }, [isOpen, settingsLoaded]);


    useEffect(() => {
        if (isOpen && sketchRef.current && preloadComplete) {
            // set the game system configuration parameters, auto starts the audio data capture
            setConfigParameters({
                preFilterArg: preFilter, 
                showOutliersArg: showOutliers, 
                showMelVisArg: showMelVis, 
                showMfccArg: showMfcc, 
                modelStructureArg: modelStructure.current, 
                numMelBandsArg: numMelBands.current, 
                frameWidthArg: frameWidth.current, 
                refreshRateArg: refreshRate.current, 
                preloadCompleteArg: preloadComplete
            });
            
            myP5.current = new p5(p => {
                let imgOpacity;
                let game;
                //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
                // define the canvas type here.... the coordinate system will provide x & y offset values for objects to use.
                // this is so that we can use the same objecgs within both P2D and WEBGL sketches
                p.canvasType = 'WEBGL'; // WEBGL or P2D

                //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

                const thisCanvasType = p.canvasType === 'WEBGL' ? p.WEBGL : p.P2D;
                
                p.preload = () => {
                    p.particleImage = p.loadImage(img);
                    p.volutiaLogo = p.loadImage(volutiaLogo);
                    p.messageFont = p.loadFont('/fonts/Comfortaa/Comfortaa-Bold.ttf');
                    p.successFont = p.loadFont('/fonts/Righteous/Righteous-Regular.ttf');
                };
                
                
                p.setup = () => {
                    p.frameRate(fps);
                    const windowWidth = sketchRef.current.clientWidth;
                    const windowHeight = sketchRef.current.clientHeight;
                    
                    p.createCanvas(windowWidth, windowHeight, thisCanvasType);
                    p.xOffset =  p.canvasType === 'WEBGL' ? 0 : p.width/2;
                    p.yOffset =  p.canvasType === 'WEBGL' ? 0 : p.height/2;
                    
                    // &&&&&&&&&&&& Insert any global canvas customizations here &&&&&&&&&&&&&&&
                    // p.angleMode(p.DEGREES);
                    // p.pixelDensity(1);
                    // &&&&&&&&&&&&&&&&&&&&&&&&&&&
                    
                    p.background(0);
                    
                    game = new MelVisSerpentSystem(p, fps);
                    setGameEnv(game);
                };
                p.draw = () => {
                    if (!ambientNoiseLevel.current) {
                        // ENV LOADER - AUDIO CHECK for ambient noise level - get the ambient noise level and set it as the volume floor within the game
                        if (calibrationTimer.current < fps * 2) { // check for x number of seconds
                            calibrationTimer.current++;
                        } else {
                            ambientNoiseLevel.current = getAmbientNoiseLevel(); // returns null if not ready
                            loggit.info('Ambient Noise Level:', ambientNoiseLevel.current);
                            if (ambientNoiseLevel.current !== null) {
                                game.calibrate(ambientNoiseLevel.current);
                                calibrationTimer.current = 0;
                            }
                        }
                        p.push(); // Isolate styling
                        p.translate(p.xOffset, p.yOffset);
                        p.textFont(p.messageFont);
                        p.textSize(20);
                        p.textAlign(p.CENTER, p.CENTER);
                        p.fill(255); // Set text color
                        p.text("CALIBRATING... please remain silent", 0, 0);
                        p.pop(); // Restore original styling
                    } else {
                        // run the game
                        
                        p.clear();
                        p.background(0);
                        
                        let volume = getCurrentVolumeInDecibels();
                        let melSpectrogram = getMelStructuredData();
                        // loggit.debug('normalized melSpectrogram:', melSpectrogram);
                        if (melSpectrogram.length > 0) {
                            melSpectrogram = ArrayStats.normalizeArray(melSpectrogram, false);
                        } else {
                            loggit.debug('melSpectrogram is null, skipping draw loop');
                            return;
                        }
                        // if volume is null, log an issue and skip the draw loop
                        if (volume === null) {
                            loggit.debug('Volume is null, skipping draw loop');
                            return;
                        }
                        
                        // draw the volutia logo
                        p.push();
                        p.translate(p.xOffset, p.yOffset);
                        // p.rotateZ(-p.PI * 0.5);
                        // p.blendMode(p.ADD);
                        imgOpacity = p.map(volume, 35, 90, 5, 20);
                        p.tint(255, imgOpacity);
                        let newWidth = 800;
                        let newHeight = p.volutiaLogo.height * (newWidth / p.volutiaLogo.width);
                        p.image(p.volutiaLogo, -newWidth / 2 + 20, -newHeight / 2, newWidth, newHeight);
                        p.pop();

                        game.update(volume, melSpectrogram); // update the game based on the user volume (breath as amplitude, melFrequency data, etc.)
                        game.run();
                    }
                };
            }, sketchRef.current);
        }

        return () => {
            if (myP5.current) {
                myP5.current.remove();
                myP5.current = null;
            }
            if (gameEnv) {
                gameEnv.destroy();
                setGameEnv(null);
            }
        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, preloadComplete]);
    
    const resetGame = () => {
        // gameEnv.reset(); // calls the reset method on the game environment
        resetAmbientNoiseLevels();
    }    

    const resetAmbientNoiseLevels = () => {
        ambientNoiseLevel.current = null;
        calibrationTimer.current = 0;
    }

    const closeModal = () => {
        stopAudioDataCapture();
        onClose();
    }


    if (!isOpen) { return null; }
    return (
        <Modal 
            show={isOpen}
            size="xl"
            fullscreen={true} 
            onHide={closeModal}
            className="ember-modal-body">

            <Modal.Header closeButton>
                <Modal.Title>SERPENTINE 🧬</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex align-items-center justify-content-center">
                <div ref={sketchRef} id="p5-canvas" style={{ width: '100%', height: '100%' }} />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <Button variant="secondary" onClick={resetAmbientNoiseLevels}>CALIBRATE</Button>
                <Button variant="secondary" onClick={resetGame}>RESET</Button>
            </Modal.Footer>
        </Modal>
    );


}

export default SerpentineModal;


