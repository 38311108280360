import React, { useRef, useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Papa from 'papaparse';

import loggit from '../../utils/Loggit';
import { buildMelFilterBank, calculateMelSpectrogram } from '../../utils/melFilterBank';

import { useFormik } from 'formik';
import * as Yup from 'yup';


const AudioProcessorModal = ({isOpen, onClose}) => {
    const closeModal = () => onClose;
    
    const formik = useFormik({
        initialValues: {
            numMelBands: 40, // Default number of Mel bands
            refreshRate: 50, // Default refresh rate
            file: null,
        },
        validationSchema: Yup.object({
            numMelBands: Yup.number()
                .min(2, 'Must be at least 2 Mel bands')
                .max(128, 'Must be at most 128 Mel bands')
                .required('Required'),
            refreshRate: Yup.number()
                .min(10, 'Must be at least 10ms')
                .max(1000, 'Must be at most 1000ms')
                .required('Required'),
            file: Yup.mixed().required('A file is required'),
        }),
        onSubmit: (values) => {
            setFile(values.file);
            setNumMelBands(values.numMelBands);
            initializeHeaders();
            processFile();
        },
    });

    const [numMelBands, setNumMelBands] = useState(formik.values.numMelBands);
    const [refreshRate, setRefreshRate] = useState(formik.values.refreshRate);// milliseconds - determines how many times per second the audio data is captured, which is essentially the resolution of the game mechanics control
    const [isFileReady, setIsFileReady] = useState(false);
    const [file, setFile] = useState(null);
    const batchNumber = useRef(0);
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };
    const processFile = async () => {
        if (file) {
            // use regex to get the batch number from the file name and set it to the state... sample file name: 'batch_1.csv'
            let batchNum = file.name.match(/batch_(\d+)\.csv/);
            if (batchNum) {
                loggit.debug('     DataProcessing >>>>> Batch number from file name:', batchNum[1])
                batchNumber.current = batchNum[1];
            } else {
                loggit.warning('WARNING      DataProcessing >>>>> Error getting batch number from file name... using default 0');
            }
            Papa.parse(file, {
                header: true,
                dynamicTyping: true,
                complete: function(results) {
                    console.log('CSV parsed:', results);
                    processAudioFiles(results.data);
                }
            });
        }
    };

    const sampleRate = 48000; // this is hard coded to match the sample rate of my computer, but it should be dynamically set TECHNICAL DEBT
    const fftSize = 2048;
    // const analyserMinDecibels = -120; // These are used by the analyserNode to calculate the frequency data 
    // const analyserMaxDecibels = -15;   // we should research if these values work data-wise, these were chosen for best visualization results
    
    const melFilterBank = useRef(Float32Array.from({length: numMelBands * (fftSize / 2)}));
    // let binSumsData = Float32Array.from({length: numMelBands});
    const trainingDataPrep = useRef([]);
    const initializeHeaders = () => {
        trainingDataPrep.current = []; // reset the training data in case the number of mel bands has changed
        let headers = [];
        for (let i = 0; i < numMelBands; i++) {
            headers.push(`${i}`);
        }
        headers.push('sound_made', 'file_path');
        trainingDataPrep.current.push(headers);
    };
    
    
    useEffect(() => {
        if (isOpen) {
            console.log('AudioProcessorModal is open:', isOpen);
            initializeHeaders();
            fetchMelFilterBank(numMelBands);
        }
    }, [isOpen, numMelBands]);

    const fetchMelFilterBank = async (nmels) => {
        try {
            melFilterBank.current = await buildMelFilterBank(fftSize, nmels, sampleRate);
            loggit.ghost('      DataProcessing >>>>> Constructed Triangular Mel Filter Bank: ', melFilterBank);
        } catch (error) {
            loggit.warning('WARNING      DataProcessing >>>>> Error building Mel Filter Bank: ', error);
            return [];    
        }
    };

    const processAudioFiles = async (data) => {
        let readPath = `/tf/sample-batches/${batchNumber.current}/wavs/`;
        let fileCount = data.length;
        let fileIndex = 0;
        for (let entry of data) {
            let filePath = readPath + entry.file_path;
            fileIndex++;
            console.log(`Processing ${filePath} - ${fileIndex} of ${fileCount}`);
    
            const response = await fetch(filePath);
            const arrayBuffer = await response.arrayBuffer();

            const offlineContext = new OfflineAudioContext(1, arrayBuffer.byteLength, sampleRate);
            const audioBuffer = await offlineContext.decodeAudioData(arrayBuffer);

            const source = offlineContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(offlineContext.destination);
            source.start();
    
            const renderedBuffer = await offlineContext.startRendering();
            
            const samplesPerChunk = sampleRate * (refreshRate / 1000);
            const chunkDuration = samplesPerChunk / sampleRate;

            // Setup a new real-time AudioContext for analysis
            const analysisContext = new AudioContext();
            const analyserNode = analysisContext.createAnalyser();
            analyserNode.fftSize = fftSize;
    
            for (let i = 0; i < renderedBuffer.duration; i += chunkDuration) {
                await processChunk(renderedBuffer, analysisContext, analyserNode, i, samplesPerChunk, chunkDuration, entry);
                loggit.debug('     DataProcessing >>>>> Still Processing');
            }
        }
        console.log('Training Data Complete: ', trainingDataPrep.current);
        setIsFileReady(true);
    };
    
    const processChunk = async (renderedBuffer, analysisContext, analyserNode, i, samplesPerChunk, chunkDuration, entry) => {
        return new Promise(resolve => {  // Ensuring this function only resolves when processing is complete
            const startSample = Math.floor(i * sampleRate);
            const endSample = Math.min(renderedBuffer.length, startSample + samplesPerChunk);
            const chunkBuffer = analysisContext.createBuffer(1, samplesPerChunk, sampleRate);
            chunkBuffer.copyToChannel(renderedBuffer.getChannelData(0).slice(startSample, endSample), 0);
    
            const chunkSource = analysisContext.createBufferSource();
            chunkSource.buffer = chunkBuffer;
            chunkSource.connect(analyserNode);
            chunkSource.start();
            chunkSource.stop(chunkDuration);  // Stop after the chunk duration
    
            chunkSource.onended = async function() {
                let floatFrequencyData = new Float32Array(analyserNode.frequencyBinCount);
                analyserNode.getFloatFrequencyData(floatFrequencyData);
                let countNegInfinity = floatFrequencyData.filter(value => !isFinite(value)).length;
                let allSame = isAllSame(floatFrequencyData);
                if (countNegInfinity === 0 && !allSame) {
                    // console.log(`Processed frequency data at ${i.toFixed(3)} s:`, floatFrequencyData);
                    let melSpectrogramData = calculateMelSpectrogram(floatFrequencyData, melFilterBank.current, numMelBands, fftSize);
                    // console.log('     DataProcessing >>>>> Mel Spectrogram Data: ', melSpectrogramData);
                    trainingDataPrep.current.push([...melSpectrogramData, entry.sound_made, entry.file_path]);
                }

                resolve();  // Resolve the promise after all processing is done
            };
        });
    };
    
    function isAllSame(frequencyData) {
        if (frequencyData.length === 0) return true;  // Optional: handle empty array as all same (silent)
        const firstValue = frequencyData[0];
        for (let i = 1; i < frequencyData.length; i++) {
            if (frequencyData[i] !== firstValue) {
                return false;  // Found a different value, so not all are the same
            }
        }
        return true;  // All values are the same
    }


    const saveDataAsCSV = () => {
        const csvData = Papa.unparse(trainingDataPrep.current, {
            header: true,
            quotes: false,
            delimiter: ','
        });
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        // const fileName = `processed_${numMelBands}mels_${file.name}`;
        // filename structure example: processed_r100m40_b1.csv
        const fileName = `processed_r${refreshRate}m${numMelBands}_b${batchNumber.current}`;
        link.download = fileName; // Name the file here
        link.click();
    };


    if (!isOpen) { return null; }

    return (
        <>
            <Modal  
                    show={isOpen}
                    size="xl"
                    fullscreen={true} 
                    onHide={closeModal}
                    className="ember-modal-body volutia-bg-light">
                <Modal.Header closeButton>
                    <Modal.Title>Upload CSV File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group controlId="refreshRate">
                            <Form.Label>Refresh Rate in milliseconds</Form.Label>
                            <Form.Control 
                                type="number" 
                                {...formik.getFieldProps('refreshRate')}
                            />
                            {formik.touched.refreshRate && formik.errors.refreshRate ? (
                                <div style={{ color: 'red' }}>{formik.errors.refreshRate}</div>
                            ) : null}
                        </Form.Group>
                        <Form.Group controlId="numMelBands">
                            <Form.Label>Number of Mel Bands</Form.Label>
                            <Form.Control 
                                type="number" 
                                {...formik.getFieldProps('numMelBands')}
                            />
                            {formik.touched.numMelBands && formik.errors.numMelBands ? (
                                <div style={{ color: 'red' }}>{formik.errors.numMelBands}</div>
                            ) : null}
                        </Form.Group>
                        <Form.Group controlId="formFile">
                            <Form.Label>Select CSV File from /volutia-app/public/tf/sample/batches/#/batch_#.csv</Form.Label>
                            <Form.Control 
                                type="file" 
                                onChange={(event) => formik.setFieldValue('file', event.currentTarget.files[0])}
                            />
                            {formik.touched.file && formik.errors.file ? (
                                <div style={{ color: 'red' }}>{formik.errors.file}</div>
                            ) : null}
                        </Form.Group>
                        <Button variant="primary" type="submit" style={{ marginRight: '10px' }}>
                            Process
                        </Button>
                        {isFileReady && (
                            <Button variant="info" onClick={saveDataAsCSV}>
                                Save Data as CSV
                            </Button>
                        )}
                    </Form>
                </Modal.Body>
                <button onClick={onClose}>Close</button>
            </Modal>
        </>
    );
};

export default AudioProcessorModal;
