import React, { createRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { handleApiError } from '../utils/helpers';
import loggit from '../utils/Loggit';
import Cookies from 'js-cookie';
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = createRef();

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required('Username is required'),
  password: Yup.string()
    .required('Password is required'),
});

function LoginModal() {
  return (
    <Formik
      className='blurred-modal'
      initialValues={{ username: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
            let recaptchaValue = '';
            // Only execute reCAPTCHA if not in development environment
            if (process.env.NODE_ENV !== 'development') {
                recaptchaValue = await recaptchaRef.current.executeAsync();
                recaptchaRef.current.reset();
            }

            loggit.ghost(`Attempting to login at API ${process.env.REACT_APP_AFM_AUTH_URI}login/ using recaptcha value: ${recaptchaValue}`)
            const response = await fetch(`${process.env.REACT_APP_AFM_AUTH_URI}login/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    username: values.username,
                    password: values.password,
                    recaptcha: recaptchaValue
                 }),
            });
            await handleApiError(response);
            
            const data = await response.json();
            loggit.ghost('Recieved response data: ', data);

            // success. set the cookie and reload the page
            Cookies.set('jwtToken', data.token, { expires: 30 });
            window.location.reload();
        } catch (error) {
            console.error('Error logging in:', error);
            alert(error.message);
        }

      }}
    >
    <Form>
        <div className="form-group">
            <label htmlFor="username">Username</label>
            <Field name="username" type="text" className="form-control" />
            <ErrorMessage name="username" component="div" className="text-danger" />
        </div>
        <div className="form-group">
            <label htmlFor="password">Password</label>
            <Field name="password" type="password" className="form-control" />
            <ErrorMessage name="password" component="div" className="text-danger" />
            {process.env.NODE_ENV !== 'development' && 
              <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={recaptchaRef} size="invisible" />
            }
        </div>
        <div className='form-group text-right'>
            <button type="submit" className="btn btn-primary auth-button mb-4">Login</button>
        </div>
    </Form>
    </Formik>

  );
}

export default LoginModal;



