//eslint-disable-next-line
import loggit from '../../utils/Loggit.js'
import { MelVisBase } from './MelVisBase.js'

class MelVisRing extends MelVisBase{
    constructor(p, options = {}) {
        // loggit.debug(`  <<<<< A new MelVisRing is instantiated >>>>>>`);
        super(p, { ...options });
        this.p = p;
        
    }

    update(melSpectrogram, windForce){
        super.update(melSpectrogram, windForce);
        // loggit.debug(`  <<<<< MelVisRing update >>>>>>`);
    }
    
    run(){
        super.run();
        // loggit.debug(`  <<<<< MelVisRing run >>>>>>`);
    }

    // render is handled by the parent class
    render(){
        for (let i = 0; i < this.melSpectrogram.length; i++){
            this.p.push();
            let x = 0;
            let y = 0;
            let ringHeight = this.spacing * 2 * i;
            let strokeWidth = this.options.strokeWidth * this.melSpectrogram[i] * 1;
            let opacity = this.melSpectrogram[i] * 255;
            // this.p.translate(0, 0, ringHeight * 0.5);
            this.p.noFill();
            this.p.stroke(218, 20, 19, opacity);
            this.p.strokeWeight(strokeWidth);
            this.p.strokeCap(this.p.SQUARE);
            this.p.arc(x + this.options.xOffset, y + this.options.yOffset, ringHeight, ringHeight, this.options.arcAngleStart, this.options.arcAngleEnd, this.p.LINE, 50); // arc(x, y, w, h, start, stop, [mode], [detail])
            this.p.pop();
        }
    }

};


export { MelVisRing };