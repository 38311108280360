// GAME SYSTEM - controls the game elements and their interactions as well as the user feedback system
// this world is subject to the user control signals generated by the AudioDataCapture component in the parent EmberModal
// Ember Game Mechanics = Breath - volume, duration and rhythm (control)

// the GameSystem class initializes the World and userFeedback classes then runs them based on the user control signals
// the userFeedback class contains the duration indicator, which provides visual feedback to the user on the duration of their breath within the context of the rules of the game
// the World class contains the visual elements for the Ember game

//eslint-disable-next-line
import loggit from '../../utils/Loggit.js'

import { MelVisMeterLateral } from "./MelVisMeterLateral.js";
import { MelVisArcWing } from "./MelVisArcWing.js";   

//================================================================================================
//================================================================================================

class MelVisSerpentSystem { 
    constructor(p) {
        this.p = p;

        // console.log(`  MelvisSerpentSystem >>>>>`);
        // initialize the Mel Visualization Elements <<<<<<<<<<<
        this.melVisMeter = new MelVisMeterLateral(p);
        this.melVisArcWing1 = new MelVisArcWing(p, {xOffset: this.p.xOffset + 0,
                                                    yOffset: this.p.yOffset - 96,
                                                    ringDirection: -1, // -1 is left, 1 is right facing
                                                    inverted: false, // false = bits come out the top half
                                                    diameter: 700});

        this.melVisArcWing2 = new MelVisArcWing(p, {xOffset: this.p.xOffset + 0,
                                                    yOffset: this.p.yOffset + 75,
                                                    ringDirection: 1, // -1 is left, 1 is right facing
                                                    inverted: true,// true = bits come out the bottom half
                                                    diameter: 700});

        this.perlinNoiseOffset = 0.02;
        this.randomWind = this.p.createVector(0, 0);

        this.defaultNoiseLevel = 30; // calculated dBFS level
        this.ambientNoiseLevel = this.defaultNoiseLevel;
        // scale from 0-100... scale is converted from user's decibel level within the calibrated range
        this.minVolume = 75; // change this value dynamically based on user's ability
        this.maxVolume = 95;
        
        this.userVolume = 0;
        this.userDuration = 0;
        this.userDurationMin = 2000;
        this.userStartTime = 0;

    }

    destroy() {
        // destroy the game elements
        this.melVisMeter = null;
        this.melVisArcWing1 = null;
        this.melVisArcWing2 = null;
    }

    calibrate(noiseLevel) {
        // calibrate the ambient noise level
        this.ambientNoiseLevel = noiseLevel;
        loggit.debug('Calibrated Ambient Noise Level:', this.ambientNoiseLevel);
    }

    update(volume, melSpectrogram) {
        this.volume = volume; // currently not doing anything with this
        
        this.melSpectrogram = [...melSpectrogram];
        // loggit.debug(`          >>>>> MelVisSerpentSystem melSpectrogram: ${this.melSpectrogram}`);

         // update the random wind force vector <<<<<<<<<< comment this out for no wind acceleration
        this.stirWind();
        let windForce = this.randomWind.copy();
        // loggit.debug(`  >>>>>>> MelVisSerpentSystem windForce: ${windForce}`);

        // update the vis elements
        // this.melVisMeter.update(melSpectrogram, windForce); 
        this.melVisArcWing1.update(melSpectrogram, windForce); 
        this.melVisArcWing2.update(melSpectrogram, windForce); 

    }

    stirWind() {
        // calculate the noise time offset based on the cycleCount and the perlinNoiseOffset
        let toff = this.p.frameCount * this.perlinNoiseOffset;
        // wind force - randomized wind direction and strength
        let wx = this.p.cos(this.p.noise(toff) * 10) * 0.07;
        let wy = this.p.sin(this.p.noise(toff + 100) * 0.07);
        // if (wy > 0) {
        //     wy *= -1;
        // }
        this.randomWind.x = wx;
        this.randomWind.y = wy;
    }
    
    run() {
        // run all of the game elements
        // this.melVisMeter.run(); 
        this.melVisArcWing1.run(); 
        this.melVisArcWing2.run(); 
    }


}

export { MelVisSerpentSystem };