// GAME SYSTEM - controls the game elements and their interactions as well as the user feedback system
// this world is subject to the user control signals generated by the AudioDataCapture component in the parent EmberModal
// Ember Game Mechanics = Breath - volume, duration and rhythm (control)

// the EmberGameSystem class initializes the World and userFeedback classes then runs them based on the user control signals
// the userFeedback class contains the duration indicator, which provides visual feedback to the user on the duration of their breath within the context of the rules of the game
// the World class contains the visual elements for the Ember game
//eslint-disable-next-line
import loggit from '../../../utils/Loggit.js'

import { UserFeedbackEnvironment } from "./EmberUserFeedback";
import { ScoreKeeperEnvironment } from "./EmberScoreKeeper";
import { WorldEnvironment } from "./EmberWorld";

//================================================================================================
//================================================================================================

class EmberGameSystem { 
    constructor(p, fps) {
        this.p = p;
        this.fps = fps;
        this.cycleCount = 0;
        this.perlinNoiseOffset = 0.02;
        this.randomWind = this.p.createVector(0, 0);
        
        this.defaultNoiseLevel = process.env.REACT_APP_DEFAULT_AMBIENT_NOISE_DBSPL; // calculated dBFS level
        this.ambientNoiseLevel = this.defaultNoiseLevel;
        // scale from 0-100... scale is converted from user's decibel level within the calibrated range
        this.minVolume = 75; // change this value dynamically based on user's ability
        this.maxVolume = 95;
        
        this.userVolume = 0;
        this.userDuration = 0;
        this.userDurationMin = 2000;
        this.userStartTime = 0;
        
        // initialize the game elements
        this.userFeedback = new UserFeedbackEnvironment(p, this.fps, this.minVolume, this.maxVolume);
        this.scoreKeeper = new ScoreKeeperEnvironment(p);
        this.world = new WorldEnvironment(p);
        
    }

    calibrate(noiseLevel) {
        // calibrate the ambient noise level
        this.ambientNoiseLevel = noiseLevel;
        loggit.debug('Calibrated Ambient Noise Level:', this.ambientNoiseLevel);
    }

    update(volume) {
        // update all of the Game elements based on user input - in this case it's the user volume
        // loggit.debug(`ambientNoiseLevel: ${this.ambientNoiseLevel}`);
        this.userVolume = this.p.map(volume, this.ambientNoiseLevel,  70 - (this.ambientNoiseLevel - this.defaultNoiseLevel), 0, 100);
        // loggit.debug(`Measured Volume: ${volume} -- Mapped Volume: ${this.userVolume}`);
        // loggit.debug(`Min Volume: ${this.minVolume} -- Max Volume: ${this.maxVolume}`);


        //++++++ GAME MECHANICS ++++++
        // user volume controls the number of particles spawned each cycle
        // uninterrupted duration (above min volume) controls the radius of the circle around which the particles are spawned
        // dropping below the minimum volume will cause the radius to shrink back to zero and the particleSpawnCount to drop back to initial value

        if (this.userVolume > this.maxVolume) {                // too loud, reduce duration
            this.userDuration -= 1000/(this.fps / 2);
        } else if (this.userVolume < this.minVolume) {          // under min vol, reset duration
            this.userDuration -= 1000/(this.fps / 4);
        } else {    // check slope for "sssss" just right, increase duration
            if (this.userStartTime === 0) this.userStartTime = this.p.millis(); // start the timer if it isn't running
            this.userDuration += 1000/this.fps;
        }
        if (this.userDuration <= 0) {
            this.userDuration = 0;
            this.userStartTime = 0;
            // ready to go again
        }

        // update the random wind force vector
        this.stirWind();
        let windForce = this.randomWind.copy();

        // update the game environments
        this.world.update(windForce, this.userVolume, this.userDuration);
        this.userFeedback.update(this.cycleCount, this.userDuration, this.userVolume, this.userStartTime, this.scoreKeeper);
        this.scoreKeeper.update();


        // increment the cycle count
        this.cycleCount++; // tracking this separately for stepcounting noise functions

    }

    stirWind() {
        // calculate the noise time offset based on the cycleCount and the perlinNoiseOffset
        let toff = this.cycleCount * this.perlinNoiseOffset;
        // wind force - randomized wind direction and strength
        let wx = this.p.cos(this.p.noise(toff) * 10) * 0.1;
        let wy = this.p.sin(this.p.noise(toff + 100) * 0.1);
        if (wy > 0) {
            wy *= -1;
        }
        this.randomWind.x = wx;
        this.randomWind.y = wy;
    }
    
    run() {
        // run all of the game elements
        this.world.run();
        this.userFeedback.run();
        this.scoreKeeper.run();
    }

    reset() {
        this.scoreKeeper.reset();
    }

}

export { EmberGameSystem };