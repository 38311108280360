import loggit from '../../utils/Loggit.js'

class MonophthongGong {
    constructor(p, options = {}) {
        this.p = p;
        this.options = {
            xOffset: this.p.xOffset,
            yOffset: this.p.yOffset,
            iam: '',
            strokeWidth: 0,
            innerRadius: 2,
            maxRadius: 80,
            innerColor: [218, 20, 19],
            outputShape: 0,
            outerColor: [10, 10, 10],
            ...options
        }
        this.font = p.successFont;
        this.fontSize = 40;
        this.myProbability = 0;
        this.volume = 0;
        this.radius = 0;

        if (this.options.outputShape === 6) {
            this.labels = {0: 'aa', 1: 'aw', 2: 'ee', 3: 'oh', 4: 'unrecognized', 5: 'uu'};
        } else if (this.options.outputShape === 12) {
            this.labels = {0: 'aa', 1: 'aw', 2: 'blowing', 3: 'ee', 4: 'ff', 5: 'oh', 6: 'ss', 7: 'th', 8: 'th-hard', 9: 'uu', 10: 'vv', 11: 'zz'};
        }
        this.labelIndex = this.getLabelIndex(this.options.iam);
    }
    
    getLabelIndex(me){
        let myIndex = 0;
        for (let key in this.labels) {
            if (this.labels[key] === me) {
                myIndex = Number(key);
                break;
            }
        }
        loggit.ghostingOff();
        loggit.ghost('****** I AM Monophthong', me, '! >>>>> and my labelIndex is:', myIndex);
        return myIndex;
    }

    update(predictions, volume) {
        if (!predictions[0]) return;
        this.volume = volume;
    
        this.myProbability = predictions[this.labelIndex];


        loggit.ghost('     Monophthong', this.options.iam, '>>>>> myProbability is:', this.myProbability);
    
        let radialAcceleration = this.p.map(this.myProbability, 0, 1, this.options.innerRadius, this.options.maxRadius);
        let radialVelocity = radialAcceleration - this.radius;
        this.radius += radialVelocity * 0.15;

        if (this.radius > radialAcceleration) this.radius = radialAcceleration;
        if (this.radius < 0) this.radius = 0;

    }

    


    run(){
        // render the power line
        this.render();
    }

    render(){
        let x = 0;
        let y = 0;
        let opacity = this.p.map(this.volume, 30, 70, 0, 255); // Assuming '30' is the max volume

        // this.p.translate(0, 0, ringHeight * 0.5);
        this.p.noStroke();

        this.p.push();
        this.p.fill(this.options.outerColor[0], this.options.outerColor[1], this.options.outerColor[2], 255);
        this.p.circle(x + this.options.xOffset, y + this.options.yOffset, this.options.maxRadius * 2); 
        this.p.pop();

        this.p.push(); // Isolate styling
        this.p.fill(this.options.innerColor[0], this.options.innerColor[1], this.options.innerColor[2], opacity);
        this.p.circle(x + this.options.xOffset, y + this.options.yOffset, this.radius * 2);
        this.p.pop();

        this.p.push(); // Isolate styling
        this.p.textFont(this.font);
        this.p.textSize(this.fontSize);
        this.p.textAlign(this.p.CENTER, this.p.CENTER);
        this.p.fill(255); // Set text color
        this.p.text(this.options.iam, x + this.options.xOffset, y + this.options.yOffset);
        this.p.pop();
    }

    
}

export { MonophthongGong };